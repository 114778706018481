<template>
  <svg width="204" height="64" viewBox="0 0 204 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.00002 62.9993C2.00002 62.9993 24.2768 61.2124 40.5964 47.3135C56.9164 33.4152 76.9582 2.11731 103 1.99942C102.848 2.09331 103 62.9993 103 62.9993H2.00002Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M202 62.9993C202 62.9993 179.723 61.2124 163.404 47.3135C147.084 33.4152 128.042 2.11731 102 1.99942C102.152 2.09331 102 62.9993 102 62.9993H202Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M101.606 39.4013C102.009 39.8046 102.663 39.8046 103.058 39.4013L108.902 33.5579C109.305 33.1584 109.305 32.5044 108.902 32.1012L107.93 31.1298C107.531 30.725 106.878 30.725 106.474 31.1298L102.332 35.2717L98.1895 31.1298C97.7898 30.725 97.1367 30.725 96.7329 31.1298L95.7617 32.1012C95.3577 32.5002 95.3577 33.1536 95.7617 33.5579L101.606 39.4013Z" fill="#1C1C1C"/>
</svg>

</template>
<script>
export default {
    name: 'header-arrow'
}
</script>
