<template>
  <div class="Register">
    <bread-crumbs :info="info" />
    <div class="Register--content">
      <img class="content__top_link" src="../assets/img/title/top_link.svg" alt="" />
      <!-- <img class="content__bot_link" src="../assets/img/title/bot_link.svg" alt="" /> -->
      <img
        class="content_bg_1"
        src="../assets/img/register/huobi-token-ht-logo 1.svg"
        alt=""
      />
      <img class="content_bg_2" src="../assets/img/register/LTC 1.svg" alt="" />
      <p class="content__title">Регистрация</p>
      <div class="content__row_info">
        <div class="content__inputs">
          <p>Имя</p>
          <v-text-field
            v-model="name"
            outlined
            dense
            placeholder="Ввести Имя"
            hide-details=""
          ></v-text-field>
        </div>
        <div class="content__inputs">
          <p>Фамилия</p>
          <v-text-field
            v-model="surname"
            outlined
            dense
            placeholder="Ввести фамилию "
            hide-details=""
          ></v-text-field>
        </div>
      </div>
      <div class="content__inputs">
        <p>Email</p>
        <v-text-field
          v-model="email"
          outlined
          dense
          placeholder="Ввести email"
          hide-details=""
        ></v-text-field>
      </div>
      <div class="content__inputs">
        <p>Пароль</p>
        <v-text-field
          v-model="password"
          outlined
          dense
          :type="show1 ? 'text' : 'password'"
          placeholder="Создать пароль"
          hide-details=""
        >
          <template v-slot:append
            ><div class="show" @click="show1 = !show1">
              {{ !show1 ? 'Показать' : 'Скрыть' }}
            </div></template
          ></v-text-field
        >
      </div>
      <div class="content__inputs">
        <p>День рождения</p>
        <div class="date_of">
          <input type="date" v-model="birth" />
          <!-- <v-select
                        v-model="birth_date.day"
                        outlined
                        dense
                        :items="days"
                        placeholder="День"
                        append-icon="mdi-chevron-down"
                        :menu-props="{
                            bottom: true,
                            offsetY: true,
                        }"
                        hide-details=""
                    ></v-select>
                    <v-select
                        v-model="birth_date.month"
                        outlined
                        dense
                        :items="monts"
                        placeholder="Месяц"
                        append-icon="mdi-chevron-down"
                        :menu-props="{
                            bottom: true,
                            offsetY: true,
                        }"
                        hide-details=""
                    ></v-select>
                    <v-select
                        v-model="birth_date.year"
                        outlined
                        dense
                        :items="years"
                        placeholder="Год"
                        append-icon="mdi-chevron-down"
                        :menu-props="{
                            bottom: true,
                            offsetY: true,
                        }"
                        hide-details=""
                    ></v-select> -->
        </div>
      </div>

      <div class="content__check">
        <v-checkbox v-model="checkbox" color="success" hide-details="">
          <template v-slot:label>
            <p>Я прочитал и согласен с</p>
          </template>
        </v-checkbox>
        <p class="forget">Условиями использования</p>
      </div>
      <button class="content__btn orange-btn" @click="reg" v-ripple>
        Зарегистрироваться
      </button>
      <p class="content__no_content">
        Есть аккаунт?
        <span @click="goto()">Войти</span>
      </p>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from '../components/ui/BreadCrumbs.vue';
import axios from '../plugins/axios';
// import vueHeader from "../src/components/core/vueHeader.vue";
// import vueFooter from "../src/components/core/vueFooter.vue";

export default {
  components: { BreadCrumbs },
  data: () => ({
    info: {
      name: 'Регистрация',
      img: require('../assets/img/title/register.png'),
    },
    show1: false,
    name: '',
    surname: '',
    email: '',
    password: '',
    birth: '',
    checkbox: false,
  }),
  computed: {
    years() {
      let arr = [];
      for (let i = new Date().getFullYear(); i >= new Date().getFullYear() - 50; i--) {
        arr.push(i);
      }
      return arr;
    },
    monts() {
      return [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ];
    },
    days() {
      let arr = [];
      for (let i = 1; i <= 31; i++) {
        arr.push(i);
      }
      return arr;
    },
  },
  methods: {
    async reg() {
      axios
        .post('/auth/register', {
          name: this.name,
          surname: this.surname,
          email: this.email,
          password: this.password,
          birth_date: this.birth,
          consent: this.checkbox,
        })
        .then((response) => {
          this.$router.push('/');
          console.log(response);
        });
    },
    goto() {
      this.$router.push('/');
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.reg();
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1050px) {
  .content_bg_1 {
    position: absolute;
    bottom: -80px !important;
    right: 109px !important;
    width: 170px;
  }
  .content_bg_2 {
    position: absolute;
    top: 10px !important;
    width: 170px;
    left: 150px !important;
  }
}
@media (max-width: 800px) {
  .BreadCrumbs--link {
    position: relative;
    right: 40px;
    bottom: 11px;
  }
  .content_bg_1 {
    top: -4vh !important;
    left: -34vw !important;
  }
  .content_bg_2 {
    top: 88vh !important;
    left: 38vw !important;
  }
  .Register--content {
    margin: 0 auto;
    width: 100% !important;
    max-width: 260px !important;
  }
  .content__row_info {
    flex-direction: column !important;
    div {
      margin-right: 0 !important;
    }
  }
}
@media (min-width: 801px) {
  // .Register {
  //   width: 100%;
  //   &--content {
  //     align-items: center;
  //   }
  // }
}
.Register {
  width: 100%;
  &--content {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 99px;
    padding-bottom: 102px;
  }
}
.content__top_link {
  position: absolute;
  top: -63px;
}
.content__bot_link {
  position: absolute;
  bottom: -62px;
}
.content_bg_1 {
  position: absolute;
  top: -21px;
  left: 0px;
}
.content_bg_2 {
  position: absolute;
  bottom: 40px;
  right: 0px;
}
.content__title {
  font-weight: 700;
  font-size: 60px;
  line-height: 75px;
  color: #000000;
  margin-bottom: 50px !important;
}
.content__inputs {
  width: 100%;
  max-width: 545px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3b3b3b;
    margin-bottom: 6px !important;
  }
  &:last-child {
    margin-bottom: 35px;
  }
}
.content__row_info {
  display: flex;
  flex-direction: row;
  div {
    width: 262px;
    margin-right: 22px;
    &:last-child {
      margin-right: 0px;
    }
  }
}
.date_of {
  display: flex;
  flex-direction: row;
  div {
    width: 168px;
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }
  }
}

.show {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #4a90e2;
  cursor: pointer;
  margin-bottom: 0px !important;
}
.content__check {
  width: 100%;
  max-width: 545px;
  display: flex;
  flex-direction: row;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4a4a4a;
    span {
    }
  }
  .forget {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4a90e2;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
    margin-top: 4px;
  }
}
.orange-btn {
  max-width: 545px;
  margin-top: 27px;
}
.content__no_content {
  margin-top: 27px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4a4a4a;
  span {
    color: #008bef;
    cursor: pointer;
  }
}
</style>
