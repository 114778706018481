<template>
  <div class="vueHeader">
    <div class="container">
      <div class="container-inner">
        <div class="nav-left">
          <div class="logo">
            <router-link to="/">
              <img src="../../img/logo.png" alt="" />
            </router-link>
          </div>
          <div v-if="selectedLang" class="lang">
            <p>
              {{ selectedLang.name }}
            </p>
            <p>
              <img :src="selectedLang.img" alt="" />
            </p>
          </div>
          <div @click="langPopup = !langPopup" class="langArrow">
            <img src="../../img/langArrow.png" alt="" />
          </div>
          <div v-if="langPopup" class="langPopup">
            <div v-for="lang in langs" :key="lang.index">
              <div @click="changeLang(lang.code, lang)" class="lang-item">
                <p>
                  {{ lang.name }}
                </p>
                <p>
                  <img :src="lang.img" alt="" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-right">
          <div class="container--blocks">
            <div class="header--nav">
              <router-link to="/"> Главная </router-link>
              <router-link :to="{ path: '/', query: { name: 'about' } }">
                О нас
              </router-link>
              <router-link :to="{ path: '/catalogPage', query: { name: 'catalogPage' } }">
                Каталог
              </router-link>
              <router-link :to="{ path: '/', query: { name: 'tech' } }">
                Технологий
              </router-link>
            </div>
            <!-- <img src="../../assets/img/header/karzina.svg" alt="" /> -->
          </div>
        </div>
        <div @click="isAppear = true" class="bar-logo">
          <img src="../../img/bar.png" alt="" />
        </div>
        <div class="body">
          <div v-bind:class="{ appear: isAppear }" class="burger">
            <p class="close-btn" @click="isAppear = false">x</p>
            <ul>
              <li class="basket-burger">
                <router-link
                  :to="{
                    name: 'cart',
                    params: { cart_data: CART },
                  }"
                  class="v-catalog-link-cart"
                >
                  <div class="cart-wrapper">
                    <img class="cart-img" src="../../img/cart.png" alt="" />
                    <div class="cart-count">
                      <p class="cart-count-inner">
                        {{ CART.length }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li class="events">
                <router-link to="/">
                  <a href="#events">Главная</a>
                </router-link>
              </li>
              <li class="news">
                <router-link :to="{ path: '/', query: { name: 'about' } }">
                  <a>О нас</a>
                </router-link>
              </li>
              <li class="policy">
                <router-link
                  :to="{ path: '/catalogPage', query: { name: 'catalogPage' } }"
                >
                  <a>Каталог</a>
                </router-link>
              </li>
              <li class="rules">
                <router-link :to="{ path: '/', query: { name: 'tech' } }">
                  <a>Технологий</a>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';

export default {
  name: 'vHeader',
  data() {
    return {
      isAppear: false,
      langPopup: false,
    };
  },
  computed: {
    ...mapGetters(['CART']),
    ...mapState(['selectedLang', 'langs']),
  },
  methods: {
    ...mapMutations(['SELECTED_LANG_MT']),
    changeLang(code, lang) {
      localStorage.setItem('lang', JSON.stringify(code));
      localStorage.setItem('langEl', JSON.stringify(lang));
      this.selectedLang = JSON.parse(localStorage.getItem('langEl'));
      location.reload();
    },
  },
  mounted() {
    this.SELECTED_LANG_MT();
    if (!localStorage.getItem('lang') || !localStorage.getItem('langEl')) {
      localStorage.setItem('lang', JSON.stringify(this.langs[0].code));
    }
  },
};
</script>

<style lang="scss" scoped>
.lang p {
  margin-right: 5px;
}

.nav-left {
  position: relative;
  display: flex;
}
.container-inner {
  position: relative;
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.lang-inner {
  display: flex;
  justify-content: space-between;
  width: 96.5px;
}
.langPopup {
  position: absolute;
  top: 61px;
  left: 197px;
  margin-left: 10px;
  font-family: 'Montserrat';
  width: 99px;
  justify-content: space-between;
  transition: 0.5s;
  opacity: 1;
}
.lang {
  align-items: center;
  margin-right: 10px;
  z-index: 2;
  margin-left: 10px;
  color: white !important;
}
a {
  color: white !important;
}
.lang-item img {
  max-width: 27px;
}
.lang-item {
  cursor: pointer;
  color: white;
}
.lang img {
  max-width: 30px;
}

.langArrow {
  // margin-left: 10px;
  margin: auto;
}
.appear {
  transition: 0.5s !important;
  position: fixed !important;
  right: 0px !important;
}
@media (min-width: 1001px) {
  .cart-count {
    left: 14px;
  }
  .bar-logo {
    display: none;
  }
}
@media (max-width: 1000px) {
  .burger {
    display: block !important;
  }
  .container--blocks {
    display: none !important;
  }
  .cart-count-inner {
    position: relative;
    padding-top: 4px;
    color: white;
    bottom: 4.5px;
  }
  .cart-count {
    width: 35px !important;
    height: 30px !important;
  }
  .cart-wrapper {
    margin-top: 34px !important;
    margin: 0 auto;
    width: 50px;
  }
}
.bar-logo {
  position: absolute;
  left: 75vw;
}
.cart-count {
  position: relative;
  left: 40px;
  bottom: 60px;
  text-align: center;
  border-radius: 50%;
  background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
}
.close-btn {
  cursor: pointer;
  position: relative;
  left: 208px;
  border: 1px solid white;
  padding-bottom: -8px;
  width: 41px;
  top: 114px;
  font-size: 39px;
  font-weight: 500;
  font-family: 'Montserrat';
}
.cart-wrapper {
  margin-top: 34px;
}
.basket-burger {
  width: 10px;
  // margin: 0 auto;
  position: relative;
  top: 17px;
  right: 6px;
}
.cart-wrapper img {
  // text-align: left;
  width: 50px;
}
.burger ul li {
  font-weight: 700;
  padding-top: 35px;
  font-size: 27px;
  text-align: left;
  list-style: none;
  color: #1b2449;
  font-family: sans-serif;
  line-height: 31.8px;
}
.burger {
  overflow: auto;
  display: none;
  color: white;
  font-family: sans-serif;
  z-index: 10;
  text-align: center;
  transition: 0.5s;
  position: fixed;
  right: -550px;
  padding: 20px 20px 20px 20px;
  top: 0px;
  width: 300px !important;
  background: rgba(23, 23, 23, 0.709);
  height: 100vh;
}
a {
  text-decoration: none !important;
}
.body h4 {
  font-family: sans-serif;
  letter-spacing: 12.7px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  margin-top: 50px;
}
.body {
  position: absolute;
}
.bar-logo img {
  width: 50px;
}
.vueHeader {
  width: 100%;
  .container {
    position: absolute;
    top: 46px;
    left: 0;
    right: 0;
    &--blocks {
      display: flex;
    }
  }
}
.header--logo {
  margin-right: 59px;
  cursor: pointer;
  align-items: center;
  width: 100%;
  max-width: 200px !important;
}
.header--lang {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-right: 20px;
  }
  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 35px;

    color: #ffffff;
    margin-right: 15px;
  }
  svg {
    cursor: pointer;
  }
}
.header--nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    position: relative;
    width: 100px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #ffffff;

    cursor: pointer;
    transition: 0.3s;

    margin-right: 20px;
    &:after {
      width: 0;
      height: 5px;
      display: block;
      position: absolute;
      bottom: -5px;
      left: 0;
      background: url('../../assets/img/header/line.svg') repeat-x;
      transition: 0.3s;
      content: '';
    }

    &:hover {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #f2b816;
      &:after {
        width: 40%;
      }
    }
  }
}
</style>
