<template>
  <aside class="main__sidebar">
    <ul class="main__navigation">
      <li
        class="main__nav-item"
        v-for="(item, index) in menuItems"
        :key="index"
      >
        <a
          href="#"
          :class="
            item.active
              ? 'main__nav-link main__nav-link_active'
              : 'main__nav-link'
          "
          @click="(event) => navigateTo(index, event)"
        >
          <component :is="item.svg"></component>
          <span class="main__nav-text">{{ item.title }}</span>
        </a>
      </li>
    </ul>
  </aside>
</template>

<script>
import svgPersonal from "./svgIcons/svgPersonal.vue";
import svgFarm from "./svgIcons/svgFarm.vue";
import svgHistory from "./svgIcons/svgHistory.vue";
import svgPayment from "./svgIcons/svgPayment.vue";
import svgChangePassword from "./svgIcons/svgChangePassword.vue";
// import { shallowRef } from "@vue/reactivity";

export default {
  
  components:{
    svgPersonal,
    svgFarm,
    svgHistory,
    svgPayment,
    svgChangePassword
  },
  data() {
    return {
      activeIndex: 0,
      menuItems: [
        { title: "Ферма", active: true, svg:'svgFarm' },
        {
          title: "Личная информация",
          active: false,
          svg: 'svgPersonal',
        },
        { title: "История", active: false, svg: 'svgHistory' },
        { title: "Оплата", active: false, svg: 'svgPayment'},
        {
          title: "Изменить пароль",
          active: false,
          svg:'svgChangePassword',
        },
      ],
    };
  },
  methods: {
    navigateTo(index, event) {
      event.preventDefault();
      this.menuItems[this.activeIndex].active = false;
      this.menuItems[index].active = true;
      this.activeIndex = index;
      this.$emit("scrollToComp", index);
    },
  },
};
</script>

<style lang="scss">
 a.main__nav-link {
   color: black !important;
 }
li{
  list-style: none;
}
a{
  text-decoration: none;
}
.main {
  &__sidebar {
    width: 400px;
    padding-left: 6px;
    flex-shrink: 0;
    @media (max-width: 1200px) {
      width: auto;
      max-width: 400px;
    }
    @media (max-width: 1216px) {
      display: none;
    }
  }
  &__navigation {
    position: sticky;
    top: 1px;
  }
  &__nav-item:last-child &__nav-link {
    border-bottom: none;
  }
  &__nav-link {
    display: flex;
    align-items: center;
    padding: 30px 41px;
    font-weight: 500;
    font-size: 18px;
    color: #3b3b3b;
    border-bottom: 1px solid #f4f2dd;
    &_active {
      border: none;
      border-radius: 10px;
      background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
      color: #efefef;
      & .main__nav-icon * {
        fill: #fff;
        fill-opacity: 1;
      }
    }
  }
  &__nav-icon {
    margin-right: 20px;
  }
}
</style>