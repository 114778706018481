import Vue from 'vue';
import Vuex from 'vuex';
import axios from '../plugins/axios';
import api from '../plugins/api';
// import ru from '../'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectedLang: null,
    isAuth: false,
    langs: [
      {
        name: 'Русский',
        code: 'ru',
        img: require('../img/lang-ru.svg'),
      },
      {
        name: 'English',
        code: 'en',
        img: require('../img/lang-en.png'),
      },
      {
        name: 'Français',
        code: 'fr',
        img: require('../img/lang-fr.png'),
      },
    ],
    paymentData: {
      cardProps: null,
      cardExp: null,
      cardCvv: null,
    },
    profile: null,
    products: [],
    cart: [],
    total: [],
    finance: null,
  },
  getters: {
    CART(state) {
      return state.cart;
    },

    TOTAL(state) {
      return state.total;
    },

    ISAUTH(state) {
      return state.isAuth;
    },
  },
  mutations: {
    AUTH_CHANGE: (state) => {
      state.isAuth = true;
    },
    SELECTED_LANG_MT: (state) => {
      if (!localStorage.getItem('lang') || !localStorage.getItem('langEl')) {
        state.selectedLang = state.langs[0];
      } else {
        state.selectedLang = JSON.parse(localStorage.getItem('langEl'));
      }
    },
    PROFILE: (state, profile) => {
      state.profile = profile.data;
    },
    CLEAR_ITEM_MUT: (state, id) => {
      state.cart.forEach((e) => {
        if (e.tariff.id === id) {
          e = null;
        }
      });
    },
    SET_PAYMENT_EXP: (state, payment) => {
      state.paymentData.cardExp = payment;
    },
    SET_PAYMENT_PROPS: (state, payment) => {
      // state.cart[0].data = cart.data;
      state.paymentData.cardProps = payment;
    },
    SET_PAYMENT_DATA: (state, payment) => {
      // state.cart[0].data = cart.data;
      state.paymentData.cardCvv = payment;
    },
    CART_COUNT: (state, cart) => {
      state.cart = cart.data;
      state.cart.forEach((e) => {
        e.qua = 0;
        e.decrQua = 0;
      });
    },
    TOTAL_COUNT: (state, total) => {
      // state.cart[0].data = cart.data;
      state.total = total.data;
    },
  },
  actions: {
    // << stripe card

    ACCEPT_PAYMENT_EXP({ commit }, payment) {
      commit('SET_PAYMENT_EXP', payment);
    },

    ACCEPT_PAYMENT_PROPS({ commit }, payment) {
      commit('SET_PAYMENT_PROPS', payment);
    },

    ACCEPT_PAYMENT_DATA({ commit }, payment) {
      commit('SET_PAYMENT_DATA', payment);
    },

    // >>

    CLEAR_ITEM({ commit }, id) {
      commit('CLEAR_ITEM_MUT', id);
    },

    async PROFILE_API({ commit }) {
      try {
        const resposne = await api.getProfile();
        commit('PROFILE', resposne);
      } catch (error) {
        console.log(error);
      }
    },
    async CART_API({ commit }) {
      try {
        const resposne = await api.getCartItems();
        commit('CART_COUNT', resposne);
      } catch (error) {
        // console.log(error);
      }
    },

    CART_TOTAL({ commit }) {
      axios
        .get('/cart/get-total', {})
        .then((total) => {
          commit('TOTAL_COUNT', total);
          return total;
        })

        .catch((error) => {
          console.log(error);
          return error;
        });
    },
  },
});
