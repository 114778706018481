<template>
  <header class="header">
    <div class="header__top container">
      <div class="header__options-group">
        <router-link to="/">
          <img src="../assets/logo.png" alt="Лого" class="header__logo" />
        </router-link>
        <div v-if="selectedLang" class="lang">
          <p>
            {{ selectedLang.name }}
          </p>
          <p>
            <img :src="selectedLang.img" alt="" />
          </p>
        </div>
        <div @click="langPopup = !langPopup" class="langArrow">
          <img src="../img/langArrow.png" alt="" />
        </div>
        <div v-if="langPopup" class="langPopup">
          <div v-for="lang in langs" :key="lang.index">
            <div @click="changeLang(lang.code, lang)" class="lang-item">
              <p>
                {{ lang.name }}
              </p>
              <p>
                <img :src="lang.img" alt="" />
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="header__language-selector">
          <div class="header__language-option header__language-option_russian">
            <span class="header__language-text">Русский</span>
          </div>
        </div> -->
      </div>
      <div class="nav-right">
        <router-link to="/">
          <a href="#events">Главная</a>
        </router-link>
        <router-link :to="{ path: '/', query: { name: 'about' } }">
          <a>О нас</a>
        </router-link>
        <router-link :to="{ path: '/catalogPage', query: { name: 'catalogPage' } }">
          <a>Каталог</a>
        </router-link>
        <router-link :to="{ path: '/', query: { name: 'tech' } }">
          <a>Технологий</a>
        </router-link>
        <div class="header__navigation">
          <router-link
            :to="{
              name: 'cart',
              params: { cart_data: CART },
            }"
            class="v-catalog-link-cart"
          >
            <div class="cart-wrapper">
              <img class="cart-img" src="../img/cart.png" alt="" />
              <div class="cart-count">
                <p class="cart-count-inner">
                  {{ CART.length }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div @click="isAppear = true" class="bar-logo">
        <img src="../img/bar.png" alt="" />
      </div>
      <div class="body">
        <div v-bind:class="{ appear: isAppear }" class="burger">
          <p class="close-btn" @click="isAppear = false">x</p>
          <ul class="cab-ul">
            <li class="basket-burger">
              <router-link
                :to="{
                  name: 'cart',
                  params: { cart_data: CART },
                }"
                class="v-catalog-link-cart"
              >
                <div class="cart-wrapper">
                  <img class="cart-img" src="../img/cart.png" alt="" />
                  <div class="cart-count">
                    <p class="cart-count-inner">
                      {{ CART.length }}
                    </p>
                  </div>
                </div>
              </router-link>
            </li>
            <li class="events">
              <router-link to="/">
                <a href="#events">Главная</a>
              </router-link>
            </li>
            <li class="news">
              <router-link :to="{ path: '/', query: { name: 'about' } }">
                <a>О нас</a>
              </router-link>
            </li>
            <li class="policy">
              <router-link :to="{ path: '/catalogPage', query: { name: 'catalogPage' } }">
                <a>Каталог</a>
              </router-link>
            </li>
            <li class="rules">
              <router-link :to="{ path: '/', query: { name: 'tech' } }">
                <a>Технологий</a>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container">
      <h1 class="header__heading">Личный кабинет</h1>
    </div>
    <div class="header__breadcrumbs">
      <div class="container">
        <router-link to="/">
          <a href="#events">Главная</a>
        </router-link>
        <span class="header__breadcrumbs-divider">/</span>
        <a href="/" class="header__breadcrumbs-link header__breadcrumbs-link_current"
          >Личный кабинет</a
        >
      </div>
    </div>
    <div class="header__wavy-block wavy-block">
      <svg
        class="wavy-block__icon"
        width="15"
        height="10"
        viewBox="0 0 15 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.60553 9.40131C7.00938 9.80456 7.6625 9.80456 8.05777 9.40131L13.9016 3.55789C14.3055 3.15837 14.3055 2.50442 13.9016 2.10117L12.9304 1.12984C12.5309 0.724988 11.8777 0.724988 11.4738 1.12984L7.33165 5.27172L3.18946 1.12984C2.78983 0.724988 2.13672 0.724988 1.73285 1.12984L0.76171 2.10117C0.357739 2.50016 0.357739 3.15357 0.76171 3.55789L6.60553 9.40131Z"
          fill="#1C1C1C"
        />
      </svg>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      isAppear: false,
      langPopup: false,
      cartSize: 5,
      menuOpen: false,
    };
  },
  methods: {
    ...mapMutations(['SELECTED_LANG_MT']),
    openMenu() {
      this.menuOpen = true;
    },
    changeLang(code, lang) {
      localStorage.setItem('lang', JSON.stringify(code));
      localStorage.setItem('langEl', JSON.stringify(lang));
      this.selectedLang = JSON.parse(localStorage.getItem('langEl'));
      location.reload();
    },
  },
  computed: {
    ...mapGetters(['CART']),
    ...mapState(['selectedLang', 'langs']),
  },
  mounted() {
    this.SELECTED_LANG_MT();
    if (!localStorage.getItem('lang') || !localStorage.getItem('langEl')) {
      localStorage.setItem('lang', JSON.stringify(this.langs[0].code));
    }
    document.addEventListener('click', this.closeMenu);
  },
};
</script>

<style lang="scss">
.header__options-group {
  position: relative;
}
.langPopup {
  position: absolute;
  font-family: sans-serif;
  top: 53px;
  left: 186px;
  margin-left: 10px;
  display: block;
  width: 96.5px;
  justify-content: space-between;
  transition: 0.5s;
  opacity: 1;
}
.lang {
  align-items: center;
  margin-right: 10px;
  width: 96.5px;
  z-index: 2;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
}
p img {
  max-width: 27px;
  // border-radius: 50%;
  color: white !important;
}
.lang-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
// //////////////////////////burger
@media (max-width: 1000px) {
  .cart-count {
    left: -71px !important;
  }
}
a {
  text-decoration: none !important;
}
.cart-count {
  position: relative;
  left: 10px;
  bottom: 62px;
  text-align: center;
  border-radius: 50%;
  background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
}
// .sidebar-close-btn {
//   top: -2px !important;
//   left: 328px !important;
//   cursor: pointer;
//   position: relative;
//   color: black;
//   color: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
//   border: 1px solid black;
//   padding-bottom: -7px;
//   width: 40px;
//   font-size: 24px;
//   border-radius: 8px;
//   font-weight: 500;
//   font-family: 'Montserrat';
//   height: 40px;
// }
.close-btn {
  cursor: pointer;
  position: relative;
  left: 208px;
  border: 1px solid white;
  padding-bottom: -8px;
  width: 41px;
  z-index: 10;
  font-size: 39px;
  font-weight: 500;
  font-family: 'Montserrat';
}
.burger {
  display: none;
  overflow: auto;
  color: white;
  z-index: 9;
  font-family: sans-serif;
  text-align: center;
  transition: 0.5s;
  position: fixed;
  right: -550px;
  padding: 66px 20px 20px 20px;
  top: 0px;
  width: 300px !important;
  background: rgba(23, 23, 23, 0.709);
  height: 100vh;
}
.cab-ul a {
  text-decoration: none;
}
.cab-ul {
  text-align: center;
  position: relative;
  bottom: 134px;
  padding: -0;
  right: 13px;
}

.burger .cab-ul li {
  font-weight: 700;
  padding-top: 35px;
  width: 273px;
  padding-left: 8px;
  font-size: 27px;
  text-align: left;
  list-style: none;
  color: #1b2449;
  font-family: sans-serif;
  line-height: 31.8px;
}
.header a {
  color: white !important;
  text-decoration: none;
}
@media (min-width: 1001px) {
  .cart-count {
    left: 15px;
    top: -37px;
  }
  .bar-logo {
    display: none;
  }
}
@media (max-width: 1000px) {
  .burger {
    display: block;
  }
  img.cart-img {
    width: 46px;
    position: relative;
    right: 105px;
  }

  .cart-count {
    width: 35px !important;
    height: 30px !important;
  }
  .cart-count-inner {
    position: relative;
    padding-top: 4px;
    color: white;
    bottom: 4.5px;
  }
  .cart-wrapper {
    margin-top: 34px !important;
    margin: 0 auto;
    width: 50px;
  }
}
.bar-logo {
  // margin: auto 20px;
  // margin-right: 25px;
  // margin: 51px -167px;
  // margin-right: 25px;
  // top: -14vh;
  // position: relative;
  // left: 166px;
}
.appear {
  transition: 0.5s;
  position: fixed;
  right: 0px;
}
.body {
  position: absolute;
}
.cart-wrapper {
  margin-top: 34px;
}

// /////////////////////////burger
@media (min-width: 1000px) {
  .nav-right a {
    margin-right: 30px;
  }
}
@media (max-width: 1000px) {
  .nav-right *:hover {
    transition: 0.5s;
    color: #f2b816;
  }
  .nav {
    padding-top: 0 !important;
  }
  .container {
    width: 455px;
  }
  .header-main {
    font-size: 13px;
  }
  .nav-left {
    margin-right: 10px;
  }
  .logo {
    margin-right: 10px !important;
  }
  .nav-right {
    display: none !important;
  }
  .header-main {
    margin-top: 0 !important;
  }
  .routing {
    margin-top: 0;
    padding-top: 5px;
  }
  .nav {
    padding-top: 5px !important;
  }
}
.nav-right {
  align-items: center;
  display: flex;
}

.header__cart-button {
  background-color: transparent;
  position: relative;
  border-style: none;
  bottom: 10px;
}
a {
  text-decoration: none;
}
.header {
  background-image: url('../assets/header-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 34px;
  padding-bottom: 109px;
  overflow: hidden;
  color: #fff;
  position: relative;
  &__heading {
    font-weight: 400;
    font-size: 72px;
    line-height: 88px;
    margin-bottom: 28px;
    @media (max-width: 900px) {
      font-size: 36px;
      line-height: 40px;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 75px;
  }
  &__options-group {
    display: flex;
    align-items: center;
  }
  &__logo {
    @media (max-width: 445px) {
      // width: 50%;
    }
  }
  &__language-selector {
    margin-left: 59px;
    padding-top: 6px;
  }
  &__language-text {
    @media (max-width: 992px) {
      display: none;
    }
  }
  &__language-option {
    display: flex;
    align-items: center;
    &::before {
      content: '';
      display: block;
      width: 28px;
      height: 20px;
      margin-right: 20.16px;
      @media (max-width: 992px) {
        margin-right: 0;
      }
    }
    &_russian::before {
      background-image: url('../assets/lang-ru.png');
    }
    font-size: 15px;
    line-height: 35px;
    &::after {
      content: url('../assets/expand_less_24px.svg');
      margin-left: 14.84px;
    }
  }
  &__navigation {
    display: flex;
    align-items: center;
    @media (max-width: 910px) {
      padding-top: 5px;
    }
  }
  &__burger-menu {
    display: none;
    @media (max-width: 904px) {
      display: block;
      margin-right: 15px;
    }
  }
  &__menu {
    display: flex;
    &.open {
      right: 0;
    }
    @media (max-width: 904px) {
      height: 100%;
      overflow: hidden;
      position: absolute;
      right: -200px;
      flex-direction: column;
      top: 0;
      background-color: white;
      z-index: 999;
      transition: right 0.7s;
      padding-top: 30px;
    }
    &-item {
      padding: 8px 32px;
      margin-left: 6px;
    }
    &-link {
      position: relative;
      color: white;
      @media (max-width: 904px) {
        color: black;
      }
      &_active {
        color: #f2b816;
        font-weight: 900;
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 50%;
          height: 2px;
          background-color: #f2b816;
          bottom: -7px;
        }
      }
    }
  }
  &__cart-button {
    position: relative;
    padding: 0;
  }
  &__cart-counter {
    display: inline-block;
    width: 12px;
    height: 12px;
    text-align: center;
    position: absolute;
    font-style: normal;
    font-weight: 900;
    font-size: 8px;
    line-height: 12px;
    color: #fff;
    background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
    border-radius: 50%;
    top: -2px;
    right: -7px;
  }
  &__breadcrumbs {
    &-divider {
      margin-right: 15px;
      margin-left: 15px;
    }
    &-link {
      line-height: 20px;
      letter-spacing: 0.7px;
      text-decoration-line: underline;
      color: #ffd800;
      &_current {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
</style>
>
