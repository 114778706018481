<template>
    <div class="wrapper">
        <div v-if="profile" class="main__finance">
            <div class="main__finance-item">
                BTC/USD
                <span class="main__money">{{ profile["BTC/USD"] }}</span>
            </div>
            <div class="main__finance-item">
                ETH/USD
                <span class="main__money">{{ profile["ETH/USD"] }}</span>
            </div>
        </div>
        <div class="main__contracts">
            <h2 class="main__subheading">Ваши контракты</h2>
            <div class="main__contract-info">
                <img
                    src="./../assets/contract-image.png"
                    alt="contract"
                    class="main__contract-logo"
                />
                <div class="main__contract-container">
                    <h3 class="main__contract-title">ЭКОНОМ</h3>
                    <p class="main__contract-description">
                        По своей сути рыбатекст является альтернативой
                        традиционному lorem ipsum, который вызывает у некторых
                        людей недоумение при попытках прочитать рыбу
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from "../plugins/axios";
// import {mapActions} from "vuex"
export default {
    name: 'Finance',
    data() {
        return {
        };
    },
    computed:{
        ...mapState(["profile"])
    },
    methods: {
        ...mapActions(["PROFILE_API"]),
    },
    mounted() {
    //    this.PROFILE_API();
    },
};
</script>

<style lang="scss">
.main {
    &__finance {
        display: flex;
        padding-top: 30px;
        margin-bottom: 55px;
        font-weight: 700;
        font-size: 24px;
        line-height: 25px;
        flex-wrap: wrap;
        &-item {
            // margin-right: 85px;
        }
    }
    &__money {
        color: #f2b816;
    }

    &__contract-info {
        display: flex;
        margin-bottom: 32px;
    }
    &__contract-logo {
        margin-right: 52px;
        width: 147px;
        @media (max-width: 500px) {
            width: 30%;
            align-self: center;
        }
    }
    &__contract-title {
        font-size: 20px;
        line-height: 88.4%;
        margin-bottom: 20px;
    }
    &__contract-description {
        max-width: 402px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #b0b0b0;
    }
}
</style>
