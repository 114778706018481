<template>
    <div class="background">
        <div class="container">
            <div class="mini-header">
                <h4>Присоединяйся</h4>
            </div>
            <div class="big-header">
                <p>
                    Будь вместе с командой <br />
                    SYMBIOT
                </p>
            </div>
            <div class="message-foot">
                Оставь нам свой имейл чтобы получать различный, но нужный спам
            </div>
            <div class="feedback">
                <p>Email</p>
                <input type="email" placeholder="your@email.com" />
                <a href="#">Отправить</a>
            </div>
            <div class="vidgets">
                <img src="../img/twitter.png" alt="" />
                <img src="../img/face.png" alt="" />
                <img src="../img/yt.png" alt="" />
            </div>
            <div class="footer_info">SYMBIOT 2021 Все права защищены</div>
        </div>
    </div>
</template>
<script>
export default {
    name:'mainFooter'
}
</script>
<style scoped>
@media (min-width: 1201px) {
    .container {
        width: 1200px;
    }
}
@media (max-width: 800px) {
}
@media (max-width: 1200px) {
    .container {
        width: 500px !important;
    }
}
@media (max-width: 1200px) {
    .container {
        width: 321px !important;
    }
}
/* //////////////////////////////////////////////////// */
.mini-header {
    font-weight: 300;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    margin-bottom: 25px;
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.8;
}
.feedback p {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    color: #100c23;
    position: relative;
    left: 46px;
    top: 7px;
}
.feedback a {
    padding-top: 19px;
    text-decoration: none;
    width: 190px;
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
    /* height: 34px; */
}
.feedback input {
    width: 421px;
    padding-left: 20px;
    height: 53px;
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05),
        0px 1px 2px rgba(0, 0, 0, 0.05);
    /* border-radius: 4px; */
}
.message-foot {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    opacity: 0.8;
    margin-bottom: 65px;
}
.big-header {
    margin-bottom: 47px;
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    /* line-height: 75px; */
    /* or 125% */

    text-align: center;
}
.container {
    padding-top: 129px;
    margin: 0 auto;
}
.feedback {
    margin-bottom: 78px;
    justify-content: center;
    display: flex;
}
.vidgets {
    margin-bottom: 44px;
    display: flex;
    justify-content: center;
}
.vidgets img:not(:first-child) {
    margin-left: 30px;
}
.footer_info {
    font-weight: 700;
    font-size: 12px;
    line-height: 11px;
    text-align: center;
    color: #ffffff;
    mix-blend-mode: normal;
}
.background {
    background-image: url("../img/footer-background.png");
    width: 100%;
    height: 1000px;
    /* height: 100vh; */
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
</style>
