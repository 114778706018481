<template>
  <svg
    class="main__nav-icon"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M13.0013 23.8337C18.9844 23.8337 23.8346 18.9834 23.8346 13.0003C23.8346 7.01724 18.9844 2.16699 13.0013 2.16699C7.01822 2.16699 2.16797 7.01724 2.16797 13.0003C2.16797 18.9834 7.01822 23.8337 13.0013 23.8337Z"
      stroke="#757575"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      clip-rule="evenodd"
      d="M13.0013 17.3337C15.3945 17.3337 17.3346 15.3936 17.3346 13.0003C17.3346 10.6071 15.3945 8.66699 13.0013 8.66699C10.6081 8.66699 8.66797 10.6071 8.66797 13.0003C8.66797 15.3936 10.6081 17.3337 13.0013 17.3337Z"
      stroke="#757575"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33984 5.34082L9.93318 9.93415"
      stroke="#757575"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.0664 16.0654L20.6597 20.6588"
      stroke="#757575"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.0664 9.93415L20.6597 5.34082"
      stroke="#757575"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.0664 9.93452L19.8906 6.11035"
      stroke="#757575"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33984 20.6588L9.93318 16.0654"
      stroke="#757575"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  // name: 
};
</script>

<style>
</style>