<template>
  <svg
    class="main__nav-icon"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5 0C8.32312 0 5.75 2.57312 5.75 5.75C5.75 8.92688 8.32312 11.5 11.5 11.5C14.6769 11.5 17.25 8.92688 17.25 5.75C17.25 2.57312 14.6769 0 11.5 0ZM14.5141 5.75117C14.5141 4.08367 13.1628 2.73242 11.4953 2.73242C9.82781 2.73242 8.47656 4.08367 8.47656 5.75117C8.47656 7.41867 9.82781 8.76992 11.4953 8.76992C13.1628 8.76992 14.5141 7.41867 14.5141 5.75117ZM20.2641 18.6887C20.2641 17.7687 15.7647 15.6699 11.4953 15.6699C7.22594 15.6699 2.72656 17.7687 2.72656 18.6887V20.2699H20.2641V18.6887ZM0 18.6875C0 14.8637 7.66187 12.9375 11.5 12.9375C15.3381 12.9375 23 14.8637 23 18.6875V23H0V18.6875Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>