<template>
    <div class="main__history">
        <h2 class="main__subheading">История</h2>
        <div class="main__table-container">
            <table class="main__table-history">
                <thead class="main__table-head">
                    <tr>
                        <th>Фото</th>
                        <th>Название</th>
                        <th>Цена</th>
                        <th>Количество</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        class="main__table-row"
                        v-for="item in goods"
                        :key="item.id"
                    >
                        <td>
                            <div class="main__table-image">
                                <img :src="item.image" alt="Товар" />
                            </div>
                        </td>
                        <td class="main__info-cell">{{ item.title }}</td>
                        <td class="main__table-price">{{ item.price }} CHF</td>
                        <td>{{ item.count }} шт.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            goods: [
                {
                    id: "1",
                    title: "Длинное название в две строки",
                    image: require("../img/battery.png"),
                    count: 1,
                    price: 3000,
                },
                {
                    id: "2",
                    title: "Длинное название в две строки",
                    image: require("../img/battery.png"),
                    count: 2,
                    price: 200,
                },
                {
                    id: "3",
                    title: "Длинное название в две строки",
                    image: require("../img/battery.png"),
                    count: 3,
                    price: 2000,
                },
                {
                    id: "4",
                    title: "Длинное название в две строки",
                    image: require("../img/battery.png"),
                    count: 1,
                    price: 1000,
                },
            ],
        };
    },
};
</script>

<style lang="scss">
.main__table-image img {
    width: 90px;
}
.main {
    &__table-history {
        width: 700px;
        font-size: 15px;
    }
    &__table-container {
        margin-bottom: 51px;
        @media (max-width: 1000px) {
            display: block;
            width: auto;
            max-width: 700px;
            overflow-y: hidden;
            overflow-x: auto;
        }
    }
    &__table-head {
        border-bottom: 1px solid #c4c4c438;
        & tr {
            height: 50px;
        }
        & th {
            font-weight: 400;
            line-height: 18px;
        }
    }
    &__table-row {
        text-align: center;
        height: 86px;
        border-bottom: 1px solid #c4c4c438;
    }
    &__info-cell {
        width: 255px;
        padding: 0 48px;
    }
    &__table-price {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #ffab5d;
    }
}
</style>
