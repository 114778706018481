<template>
  <div>
    <mainHeader />
    <preview />
    <shale />
    <!-- <vcatalog /> -->
    <tech />
    <mainFooter />
  </div>
</template>

<script>
import mainHeader from './mainHeader.vue';
import mainFooter from './mainFooter.vue';
// import vcatalog from '../components/v-catalog.vue';
import preview from './preview.vue';
import shale from './shale.vue';
import tech from './tech.vue';

export default {
  name: 'v-main-wrapper',
  components: { mainHeader, mainFooter, preview, shale, tech },
  mounted() {
    if (this.$route.query.name === 'catalog') {
      setTimeout(() => {
        document.querySelector('#catalog').scrollIntoView({ behavior: 'smooth' });
      }, 1000);
    }
    if (this.$route.query.name === 'tech') {
      setTimeout(() => {
        document.querySelector('#tech').scrollIntoView({ behavior: 'smooth' });
      }, 1000);
    }
    if (this.$route.query.name === 'about') {
      setTimeout(() => {
        document.querySelector('#about').scrollIntoView({ behavior: 'smooth' });
      }, 1000);
    }
  },
  computed: {},
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'Gotham Pro';
  src: url('../assets/fonts/gothampro.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../assets/fonts/gothampro_black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../assets/fonts/gothampro_bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../assets/fonts/gothampro_light.ttf');
  font-weight: 300;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

table {
  border-collapse: collapse;
}
input {
  border: none;
  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

html {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.container {
  width: 1161px;
  margin-right: auto;
  margin-left: auto;
}

.button {
  background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  padding: 15px 0;
  width: 210px;
  font-family: inherit;
}
.button_no-color {
  background: #ffffff;
  border: 1px solid #999999;
  color: #3d3935;
}
.wavy-block {
  position: absolute;
  width: 204px;
  height: 64px;
  text-align: center;
  line-height: 64px;
  background-image: url('../assets/wavy-bg.png');
  background-repeat: no-repeat;
  background-position: 50% 1px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
* {
  scroll-behavior: smooth;
  margin: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

// @font-face {
//   font-family: "Basis Grotesque Pro";
//   src: local("Basis Grotesque Pro Regular"),
//     local("Basis-Grotesque-Pro-Regular"),
//     url("../assets/fonts/GrotesquePro-Regular.woff2") format("woff2"),
//     url("../assets/fonts/GrotesquePro-Regular.woff") format("woff"),
//     url("../assets/fonts/GrotesquePro-Regular.ttf") format("truetype");
//   font-weight: 400;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Gotham Pro Narrow Medium";
//   src: url("fonts/GothamProNarrow-Medium.eot");
//   src: url("fonts/GothamProNarrow-Medium.eot?#iefix")
//       format("embedded-opentype"),
//     url("fonts/GothamProNarrow-Medium.woff") format("woff"),
//     url("fonts/GothamProNarrow-Medium.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Gotham Pro Black";
//   src: url("fonts/GothamPro-Black.eot");
//   src: url("fonts/GothamPro-Black.eot?#iefix") format("embedded-opentype"),
//     url("fonts/GothamPro-Black.woff") format("woff"),
//     url("fonts/GothamPro-Black.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
