<template>
    <footer class="footer">
        <div class="footer__wavy-block wavy-block">
            <svg
                class="wavy-block__icon"
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.60553 9.40131C7.00938 9.80456 7.6625 9.80456 8.05777 9.40131L13.9016 3.55789C14.3055 3.15837 14.3055 2.50442 13.9016 2.10117L12.9304 1.12984C12.5309 0.724988 11.8777 0.724988 11.4738 1.12984L7.33165 5.27172L3.18946 1.12984C2.78983 0.724988 2.13672 0.724988 1.73285 1.12984L0.76171 2.10117C0.357739 2.50016 0.357739 3.15357 0.76171 3.55789L6.60553 9.40131Z"
                    fill="#1C1C1C"
                />
            </svg>
        </div>
        <div class="footer__welcome-message">Присоединяйся</div>
        <div class="footer__heading">Будь вместе с командой SYMBIOT</div>
        <div class="footer__info-text">
            Оставь нам свой имейл чтобы получать различный, но нужный спам
        </div>
        <div class="footer__email-form">
            <div class="footer__input-box">
                <div class="footer__input-text">Email</div>
                <input
                    type="text"
                    class="footer__email-input"
                    placeholder="your@email.com"
                />
            </div>
            <button type="button" class="button footer__button">
                Отправить
            </button>
        </div>
        <nav class="footer__nav">
            <a href="#" class="footer__link"
                ><svg
                    width="19"
                    height="17"
                    viewBox="0 0 19 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M19 2.34633C18.2885 2.67278 17.5339 2.88709 16.7612 2.98216C17.5648 2.48173 18.183 1.6909 18.4739 0.747209C17.7088 1.21771 16.8717 1.54919 15.9987 1.72733C15.262 0.912023 14.2317 0.450216 13.1538 0.452155C11.002 0.452155 9.25551 2.26016 9.25551 4.49046C9.25551 4.80737 9.28993 5.11624 9.35733 5.41117C6.11725 5.24248 3.24566 3.63532 1.3227 1.19149C0.986934 1.78725 0.795414 2.48173 0.795414 3.22207C0.795414 4.6226 1.48331 5.85897 2.52903 6.58335C1.9099 6.56369 1.30434 6.39044 0.763171 6.07815V6.12965C0.763171 8.08588 2.10696 9.71828 3.88931 10.0901C3.31528 10.2511 2.71341 10.2748 2.12927 10.1592C2.62552 11.7639 4.06556 12.9313 5.77057 12.9653C4.43672 14.0467 2.75619 14.6936 0.929478 14.6936C0.618863 14.6933 0.308525 14.6744 0 14.6371C1.72489 15.7827 3.77428 16.4521 5.97543 16.4521C13.1453 16.4521 17.0654 10.2968 17.0654 4.95898C17.0654 4.78439 17.0654 4.60979 17.0533 4.43645C17.8161 3.86457 18.4748 3.15688 18.9988 2.34633H19Z"
                        fill="white"
                    /></svg
            ></a>
            <a href="#" class="footer__link"
                ><svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3 0.452148H13C14.6569 0.452148 16 1.79529 16 3.45215V13.4521C16 15.109 14.6569 16.4521 13 16.4521H3C1.34315 16.4521 0 15.109 0 13.4521V3.45215C0 1.79529 1.34315 0.452148 3 0.452148ZM11 10.9137H12.5556L13 8.83676H11V7.45215C10.8945 6.86556 11.0596 6.44468 11.8889 6.52907H13V4.45215C12.8118 4.51025 12.1663 4.45215 11.4444 4.45215C9.84705 4.45215 8.7735 5.44627 8.77778 7.22138V8.83676H7V10.9137H8.77778V16.4521H11V10.9137Z"
                        fill="white"
                    />
                </svg>
            </a>
            <a href="#" class="footer__link"
                ><svg
                    width="22"
                    height="17"
                    viewBox="0 0 22 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.55469 11.8811V5.02344L14.6658 8.46368L8.55469 11.8811ZM21.78 3.90382C21.78 3.90382 21.5649 2.33571 20.9061 1.64538C20.0689 0.739026 19.1314 0.735597 18.7012 0.683022C15.6237 0.452148 11.0061 0.452148 11.0061 0.452148H10.9963C10.9963 0.452148 6.37756 0.452148 3.3 0.683022C2.86856 0.735597 1.93111 0.740169 1.09389 1.64538C0.433889 2.33571 0.22 3.90382 0.22 3.90382C0.22 3.90382 0 5.74396 0 7.58409V9.31221C0 11.1523 0.22 12.9925 0.22 12.9925C0.22 12.9925 0.433889 14.5617 1.09389 15.2521C1.93111 16.1573 3.02989 16.1287 3.52 16.2236C5.28 16.3984 11 16.4521 11 16.4521C11 16.4521 15.6224 16.4453 18.7 16.2144C19.1314 16.1618 20.0689 16.1573 20.9061 15.2509C21.5661 14.5594 21.78 12.9913 21.78 12.9913C21.78 12.9913 22 11.1512 22 9.31107V7.58409C22 5.74396 21.78 3.90382 21.78 3.90382Z"
                        fill="white"
                    />
                </svg>
            </a>
        </nav>
        <div class="footer__copyrights">SYMBIOT 2021 Все права защищены</div>
    </footer>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
button {
    background: url(/assets/bitcoin.jpg) no-repeat 232px 35px,
        linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
}
.footer {
    position: relative;
    text-align: center;
    background-image: url("../assets/footer-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gotham Pro";
    padding-top: 129px;
    padding-bottom: 114px;
    color: #ffffff;
    @media (max-width: 840px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }
    &__wavy-block.wavy-block {
        transform: translateX(-50%) rotate(180deg);
        bottom: initial;
        top: 0;
    }
    &__welcome-message {
        font-weight: 300;
        font-size: 18px;
        margin-bottom: 25px;
    }
    &__heading {
        max-width: 785px;
        margin-right: auto;
        margin-left: auto;
        font-weight: 900;
        font-size: 60px;
        line-height: 75px;
        margin-bottom: 47px;
        @media (max-width: 720px) {
            font-size: 40px;
            line-height: 50px;
        }
    }
    &__info-text {
        font-size: 21px;
        line-height: 32px;
        margin-bottom: 65px;
    }
    &__email-form {
        display: flex;
        justify-content: center;
        margin-bottom: 41px;
    }
    &__button.button {
        width: 190px;
        border-radius: 0 4px 4px 0;
    }
    &__input-box {
        background-color: #fff;
        padding-top: 14px;
        padding-left: 20px;
        width: 424px;
        flex-shrink: 1;
    }
    &__input-text {
        font-weight: 700;
        font-size: 10px;
        line-height: 10px;
        color: #100c23;
        text-align: left;
    }
    &__email-input {
        font-size: 13px;
        line-height: 12px;
        text-align: left;
        display: block;
        ::placeholder {
            color: rgba($color: #000000, $alpha: 0.3);
        }
    }
    &__nav {
        margin-bottom: 46px;
    }
    &__link {
        margin-right: 31px;
        &:last-child {
            margin-right: 0;
        }
    }
    &__copyrights {
        font-weight: 700;
        font-size: 12px;
        line-height: 11px;
    }
}
</style>
