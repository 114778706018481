<template>
  <div class="Enter">
    <vue-header />
    <bread-crumbs :info="info" />
    <div class="Enter--content">
      <img class="content__top_link" src="../assets/img/title/top_link.svg" alt="" />
      <img class="content__bot_link" src="../assets/img/title/bot_link.svg" alt="" />
      <img
        class="content_bg_1"
        src="../assets/img/login/chainlink-link-logo 1.svg"
        alt=""
      />
      <img class="content_bg_2" src="../assets/img/login/tron-trx-logo 1.svg" alt="" />

      <p class="content__title">Войти</p>
      <div class="content__inputs">
        <p>Email</p>
        <v-text-field
          v-model="email"
          outlined
          dense
          placeholder="Ввести email"
          hide-details=""
        ></v-text-field>
      </div>
      <div class="content__inputs">
        <p>Пароль</p>
        <v-text-field
          v-model="password"
          outlined
          dense
          :type="show1 ? 'text' : 'password'"
          placeholder="Ввести пароль"
          hide-details=""
        >
          <template v-slot:append
            ><div class="show" @click="show1 = !show1">
              {{ !show1 ? 'Показать' : 'Скрыть' }}
            </div></template
          ></v-text-field
        >
      </div>
      <div class="content__check">
        <v-checkbox v-model="checkbox" color="success" hide-details="">
          <template v-slot:label>
            <p>Запомнить меня</p>
          </template>
        </v-checkbox>
        <p class="forget">Забыли пароль?</p>
      </div>
      <button class="content__btn orange-btn" @click="reg" v-ripple>Войти</button>
      <p class="content__no_content">
        Нет аккаунта?
        <span @click="goto()">Зарегистрироваться</span>
      </p>
    </div>
    <vue-footer />
  </div>
</template>

<script>
import vueHeader from '../components/core/vueHeader.vue';
import vueFooter from '../components/core/vueFooter.vue';
import BreadCrumbs from '../components/ui/BreadCrumbs.vue';
import axios from '../plugins/axios';

export default {
  components: { BreadCrumbs, vueHeader, vueFooter },
  data: () => ({
    info: {
      name: 'Войти',
      img: require('../assets/img/title/login.png'),
    },
    show1: false,
    checkbox: false,
    email: '',
    password: '',
  }),
  methods: {
    async reg() {
      axios
        .post('/auth/login', {
          email: this.email,
          password: this.password,
          consent: this.consent,
        })
        .then((response) => {
          this.$router.push('/');
          console.log(response);
          localStorage.setItem('token', response.data.access_token);
        });
    },
    goto() {
      this.$router.push('/register');
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1050px) {
  .content_bg_1 {
    position: absolute;
    bottom: -80px !important;
    right: 109px !important;
    width: 170px;
  }
  .content_bg_2 {
    position: absolute;
    top: 10px !important;
    width: 170px;
    left: 150px !important;
  }
}
@media (max-width: 800px) {
  .content_bg_1 {
    bottom: -80px !important;
    right: -20px !important;
  }
  .content_bg_2 {
    top: 10px !important;
    left: -58px !important;
  }
  .Enter--content {
    margin: 0 auto;
    width: 100% !important;
    max-width: 390px !important;
  }
}
.Enter {
  width: 100%;
  &--content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 92px;
    padding-bottom: 96px;
  }
}
.content__top_link {
  position: absolute;
  top: -63px;
}
.content__bot_link {
  position: absolute;
  bottom: -62px;
}
.content_bg_1 {
  position: absolute;
  bottom: -25px;
  right: 0px;
}
.content_bg_2 {
  position: absolute;
  top: 84px;
  left: 79px;
}
.content__title {
  font-weight: 700;
  font-size: 70px;
  line-height: 75px;
  color: #000000;
  margin-bottom: 50px !important;
}
.content__inputs {
  width: 100%;
  max-width: 545px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3b3b3b;
    margin-bottom: 6px !important;
  }
  &:last-child {
    margin-bottom: 35px;
  }
}
.show {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #4a90e2;
  cursor: pointer;
  margin-bottom: 0px !important;
}
.content__check {
  width: 100%;
  max-width: 545px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4a4a4a;
  }

  .forget {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #008bef;
    cursor: pointer;
  }
}
.orange-btn {
  max-width: 545px;
  margin-top: 27px;
}
.content__no_content {
  margin-top: 27px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4a4a4a;
  span {
    color: #008bef;
    cursor: pointer;
  }
}
</style>
