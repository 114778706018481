import { render, staticRenderFns } from "./catalogPage.vue?vue&type=template&id=653a0ca7&"
import script from "./catalogPage.vue?vue&type=script&lang=js&"
export * from "./catalogPage.vue?vue&type=script&lang=js&"
import style0 from "./catalogPage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports