<template>
  <div>
    <v-card-item v-for="item in CART" :key="item.tariff.id" :item="item" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import vCardItem from './v-card-item.vue';

export default {
  components: { vCardItem },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['CART']),
  },
};
</script>
