<template>
  <div>
    <main-header />
    <cabinet-section />
    <main-footer />
  </div>
</template>
<script>
import MainFooter from "../components/CabFooter.vue";
import MainHeader from "../components/CabHeader.vue";
import CabinetSection from "../components/CabinetSection.vue";
export default {
  components: { MainHeader, MainFooter, CabinetSection },
  name: "cab",
};
</script>
