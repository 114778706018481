<template>
  <div class="v-cart" :class="{ bg_1: step < 2, bg_2: isNext, bg_3: isLast }">
    <template v-if="this.CART.length !== 0">
      <b-container>
        <div class="v-cart-steps col-8 d-flex">
          <div class="item item_cart" :class="{ item_cart_2: step > 1 }">1.Корзина</div>
          <div
            class="item item_price"
            :class="{
              item_price_3: step > 1,
              item_price_4: step > 2,
            }"
          >
            2.Оплата
          </div>
          <div class="item item_check" :class="{ item_check_4: step > 2 }">
            2.Выполнено
          </div>
        </div>
        <v-card-item-parent v-show="step === 1" />
        <v-from-buy v-show="step === 2" />
        <v-sucsess v-show="step === 3" />
        <div :class="{ next_step: isNext }" v-show="step < 3" class="v-cart-total">
          <div class="v-cart-price-full item">
            <span> Всего: {{ TOTAL.total }} </span>
          </div>
          <div class="v-cart-price-sale item">
            <span> Скидка </span>
            <span> {{ TOTAL.discount }}CHF </span>
          </div>
          <div class="v-cart-price item">
            <span>Итого: {{ TOTAL.total_after_discount }}CHF</span>
          </div>
        </div>

        <div class="v-cart-buttons" :class="{ btn_next_step: isNext }" v-show="step < 3">
          <div class="v-catalog-item-buttons">
            <router-link class="v-catalog-item-about-link" :to="{ path: '/' }">
              На главную
            </router-link>
          </div>
          <button class="v-cart-back" v-show="isNext" @click="BackStep">Назад</button>
          <button class="v-cart-item-next-step" @click="NextStep">Продолжить</button>
        </div>
      </b-container>
    </template>
    <template v-else>
      <h2 class="text-center">К сожалению тут пока ничего нет =(</h2>
      <div class="v-cart-backToCatalog">
        <router-link class="v-catalog-item-about-link" to="/catalogPage">
          В каталог
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import VCardItemParent from './v-cart-item-parent.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import VFromBuy from '@/components/v-from-buy';
import VSucsess from '@/components/v-sucsess';
import { paymentInstance } from '../plugins/axios';
import axios from '../plugins/axios';
// import api from '../plugins/api';

export default {
  name: 'v-cart',
  components: { VSucsess, VFromBuy, VCardItemParent },
  data: () => ({
    CartIsNotEmpty: false,
    step: 1,
    isNext: false,
    isLast: false,
    total: '',
    res: '', // вопросики
    // cart_data так же не ясен
  }),
  methods: {
    ...mapActions(['CART_API', 'CART_TOTAL']),
    addToCart(data) {
      this.ADD_TO_CART(data);
    },
    NextStep() {
      this.isNext = true;
      if (this.step > 2) {
        this.isLast = true;
        this.isNext = false;
      }

      //   << COUNT IN THE QUANTITY
      if (this.step === 1) {
        this.step = 3;
      }
      // COUNT IN THE QUANTITY >>
      if (this.step === 2) {
        const params = new URLSearchParams();
        params.append('type', 'card');
        params.append('card[number]', this.paymentData.cardProps);
        params.append('card[exp_month]', this.paymentData.cardExp.slice(0, 2));
        params.append('card[exp_year]', 20 + this.paymentData.cardExp.slice(2, 4));
        params.append('card[cvc]', this.paymentData.cardCvv);
        paymentInstance
          .post('/payment_methods', params)
          .then((res) => {
            if (res.status === 200) {
              var queryString = Object.keys(res.data)
                .map((key) => {
                  return (
                    encodeURIComponent(key) + '=' + encodeURIComponent(res.data[key])
                  );
                })
                .join('&');
              // axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
              axios
                .post(`/cart/payment`, {
                  payment_method: res.data,
                })
                .then((response) => {
                  alert('sdsds');
                  if (response.status === 302) {
                    window.location.href = response.request.responseURL;
                  }
                  console.log('swagger');
                  this.step++;
                })
                .catch((error) => {
                  console.log(error, 'swagger error !');
                });
            }
          })
          .catch((error) => {
            console.log(error, 'stripe error!');
          });
      }
    },
    BackStep() {
      this.step--;
      this.isNext = false;
      // if (this.step < 2) {
      // }
    },
    // handlePayment(cartData) {},
  },
  // props: {
  //   card: {
  //       type: Object,
  //       default: () => [],
  //   },
  // },
  computed: {
    ...mapState(['paymentData']),
    ...mapGetters(['CART', 'TOTAL']),
  },
  mounted() {
    this.CART_TOTAL().then(() => {}), this.CART_API().then(() => {});
  },
};
</script>

<style lang="scss">
.v-cart-backToCatalog {
  width: 150px;
  margin: 0 auto;
}
.text-center {
  text-align: center;
  margin: 30vh auto;
}

.v-catalog-item-about-link {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #fb4030 !important;

  padding: 16px 27px;
  margin-right: 10px;
  box-sizing: border-box;
  border: 1px solid #fb4330;
  border-radius: 4px;
  text-decoration: none;
}
.container {
  margin-top: 100px;
}
.v-cart {
  .v-cart-steps {
    position: relative;
    bottom: 18px;
    // padding-bottom: 30px !important;
    margin: 0 auto 100px auto;
    gap: calc(13px + 41px);
    justify-content: center;
    .item {
      width: max-content;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.02em;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        width: 114px;
        height: 1px;
        background: #999999;
        top: 30%;
        left: 50%;
        transform: translate(50%, -100%);
      }
      &:first-child {
        color: #f4a31b;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }

      &_cart {
        position: relative;

        &:before {
          content: '';
          display: block;
          background: url('../assets/icons/shopping_cart_24px.png');
          width: 30px;
          height: 30px;
        }
        &_2 {
          color: #f4a31b;
          &:after {
            background: #f4a31b;
          }
        }
      }
      &_price {
        &:before {
          content: '';
          display: block;
          background: url('../assets/icons/account_balance_wallet_24px.png');
          width: 30px;
          height: 30px;
        }
        &_3 {
          color: #f4a31b;
          &:before {
            background: url('../assets/icons/account_balance_wallet_24px_check.png');
          }
        }
        &_4 {
          &:after {
            background: #f4a31b;
          }
        }
      }
      &_check {
        &:before {
          content: '';
          display: block;
          background: url('../assets/icons/assignment_turned_in_24px.png');
          width: 30px;
          height: 30px;
        }
        &_4 {
          color: #f4a31b;
          &:before {
            background: url('../assets/icons/assignment_turned_in_24px_check.png');
          }
        }
      }
    }
  }
  .v-cart-buttons {
    display: flex;
    gap: 25px;
    justify-content: flex-end;
    .v-cart-item-link-back,
    .v-cart-back {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      height: 61px;
      margin: 48px 0px;
      color: #fb4030 !important;
      padding: 20px 60px;
      box-sizing: border-box;
      border: 1px solid #fb4330;
      filter: drop-shadow(0px 2px 4px rgba(32, 5, 97, 0.1))
        drop-shadow(0px 1px 2px rgba(32, 5, 97, 0.08));
      border-radius: 4px;
      text-decoration: none;
    }
    .v-cart-item-next-step {
      padding: 20px 50px;
      height: 61px;
      margin: 48px 0px;
      box-sizing: border-box;
      background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
      box-shadow: 0px 2px 4px rgb(32 5 97 / 10%), 0px 1px 2px rgb(32 5 97 / 8%);
      border-radius: 4px;
      border: none;
      outline: none;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }
  }
  .v-cart-total {
    max-width: 410px;
    width: 100%;
    margin-left: auto;
    margin-bottom: 45px;
    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .v-cart-price-full {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #3d3935;
    }
    .v-cart-price-sale {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #f3a919;
    }
    .v-cart-price {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #3d3935;
    }
  }
  .next_step {
    margin: 50px auto 50px auto;
  }
  .btn_next_step {
    margin-top: 140px;
    justify-content: center;
  }
}
.bg_1 {
  background: url('../assets/bg_step_1.png') no-repeat;
  height: 100vh;
  background-size: contain;
}
.bg_2 {
  background: url('../assets/bg_step_2.png') no-repeat;
  height: 100vh;
  background-size: contain;
}
.bg_3 {
  background: url('../assets/bg_3.png') no-repeat;
  height: 100vh;
  background-size: contain;
}
</style>
