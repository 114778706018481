<template>
  <svg
    class="main__nav-icon"
    width="21"
    height="23"
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.593 2.3H18.4C19.665 2.3 20.7 3.335 20.7 4.6V20.7C20.7 21.965 19.665 23 18.4 23H2.3C2.139 23 1.9895 22.9885 1.84 22.9655C1.3915 22.8735 0.989 22.6435 0.6785 22.333C0.4715 22.1145 0.299 21.873 0.184 21.597C0.069 21.321 0 21.0105 0 20.7V4.6C0 4.278 0.069 3.979 0.184 3.7145C0.299 3.4385 0.4715 3.1855 0.6785 2.9785C0.989 2.668 1.3915 2.438 1.84 2.346C1.9895 2.3115 2.139 2.3 2.3 2.3H7.107C7.59 0.966 8.855 0 10.35 0C11.845 0 13.11 0.966 13.593 2.3ZM4.60156 6.90039H16.1016V9.20039H4.60156V6.90039ZM16.1016 11.5H4.60156V13.8H16.1016V11.5ZM12.6516 16.0996H4.60156V18.3996H12.6516V16.0996ZM10.3508 2.0127C10.8223 2.0127 11.2133 2.4037 11.2133 2.8752C11.2133 3.3467 10.8223 3.7377 10.3508 3.7377C9.87928 3.7377 9.48828 3.3467 9.48828 2.8752C9.48828 2.4037 9.87928 2.0127 10.3508 2.0127ZM2.30078 20.6996H18.4008V4.59961H2.30078V20.6996Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>