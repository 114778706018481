import Vue from 'vue';
import VueRouter from 'vue-router';
import Enter from '../views/login.vue';
import Register from '../views/Register.vue';
import cab from '../components/cab.vue';
import vCart from '../components/v-cart';
import VMainWrapper from '@/components/v-main-wrapper';
import catalogPage from '@/components/catalogPage.vue';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/catalogPage',
    name: 'catalogPage',
    meta: { auth: true },
    component: catalogPage,
  },
  {
    path: '/',
    name: 'VMainWrapper',
    // meta: { auth: true },
    component: VMainWrapper,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/login',
    name: 'enter',
    meta: {},
    component: Enter,
  },
  {
    path: '/cab',
    name: 'cab',
    meta: { auth: true },
    component: cab,
  },
  {
    path: '/cart',
    name: 'cart',
    meta: { auth: true },
    component: vCart,
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let isAuth = null;
  if (localStorage.getItem('token') !== null) {
    isAuth = true;
  }
  const currentUser = store.getters['ISAUTH'];

  const requiredAuth = to.matched.some((record) => record.meta.auth);
  console.log(currentUser, requiredAuth);
  if (requiredAuth && !currentUser && !isAuth) {
    alert('Необходимо авторизоваться');
    next('/login');
  } else {
    next();
  }
});

export default router;
