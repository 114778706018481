<template>
  <div id="catalog" class="v-catalog">
    <v-catalog-item v-for="item in info.data" :key="item.index" :item="item">
    </v-catalog-item>
  </div>
</template>

<script>
import VCatalogItem from './v-catalog-item.vue';
import axios from '../plugins/axios';

export default {
  name: 'v-catalog',
  components: { VCatalogItem },
  data: () => ({
    info: [],
  }),
  methods: {
    async tarrifs() {
      await axios.get('/tariffs').then((response) => {
        this.info = response.data;
      });
    },
  },
  mounted() {
    this.tarrifs();
  },
};
</script>

<style lang="scss">
.v-catalog-item-wrapper {
  text-align: center;
}
.row {
  justify-content: center;
}
.v-catalog {
  background: url('../assets/catalog_bg.png') no-repeat;
}
.bg_top {
  position: fixed;
  left: 0;
  width: 292px;
  height: 292px;
  background: url('../assets/bitcoin-bg.png') left no-repeat;
}
.bg_bottom {
  background: url('../assets/bg_bottom.png') right no-repeat;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 292px;
  height: 292px;
}
</style>
