<template>
  <div class="main__income">
    <h2 class="main__subheading">Прибыль по контракту “Эконом”</h2>
    <div class="main__income-card income-card">
      <div class="income-card__info">Прибыль баланс в BTC</div>
      <div class="income-card__amount">{{ income }}</div>
      <div class="income-card__dollars">$ {{ incomeDollars }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      income: "0.00000000",
      incomeDollars: "0.00",
    };
  },
};
</script>

<style lang="scss">
.main__income {
  margin-bottom: 66px;
}
.income-card {
  background: url("/assets/bitcoin.jpg") no-repeat 232px 35px,
    linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
  box-shadow: 0px 8px 24px 6px rgba(237, 163, 181, 0.303786);
  border-radius: 16px;
  width: 369px;
  height: 175px;
  color: #fff;
  padding-top: 36px;
  padding-bottom: 39px;
  padding-left: 40px;
  @media (max-width: 500px) {
    width: 100%;
  }
  &__info {
    margin-bottom: 17px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
  &__amount {
    margin-bottom: 17px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
  &__dollars {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  &__account-content {
    margin-top: -7px;
  }
}
</style>