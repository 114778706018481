<template>
    <div id="about" class="container-preview">
        <div class="header-arrow">
            <headerarrow />
        </div>
        <p class="preview-mini-header">About</p>
        <p v-if="info" class="preview-header">
            {{ info.data.title }}
        </p>
        <div v-if="info" class="about-text">
            {{ info.data.description }}
        </div>
        <div v-for="item in info" :key="item.id" class="about-icons">
            <div v-for="item2 in item.recommendations" :key="item2.id">
                <div v-if="info" class="login-icon">
                    <img :src="item2.img" alt="" />
                    <p>{{ item2.name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import headerarrow from "../components/svg/header-arrow.vue";
import axios from "../plugins/axios";
export default {
    components: {
        headerarrow,
    },
    props: {
        msg: String,
    },
    data() {
        return {
            info: "",
            name: "",
            surname: "",
            email: "",
            password: "",
            birth: "",
            consent: false,
            product_id: "2",
        };
    },
    methods: {
        async AboutUs() {
            await axios.get("/about-us").then((response) => {
                this.info = response.data;
            });
        },
    },
    mounted() {
        this.AboutUs();
    },
};
</script>
<style scoped>
.header-arrow[data-v-35056c30] {
    width: 210px;
    z-index: 10000000;
    position: relative;
    /* background: black; */
    margin: 0 auto;
    bottom: 138px;
}
.container-preview {
    transform: translateZ(0) scale(1);
    transition-duration: 4s;
    padding: 80px 0 90px 0;
    background-image: url("https://www.symbiote.ch/img/about-bg-icon.svg");
    background-repeat: no-repeat;
    background-size: 150px 150px;
    background-color: #ffffff;
    background-position: left 100%;
    margin-left: -25px;
    padding-left: 25px;
    /* width: 1200px; */
    /* margin: 0 auto; */
}
.about-icons p {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    line-height: 17px;
    color: #000000;
    text-align: center;
}
.about-text {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
}
* {
    font-family: sans-serif;
}
.preview-mini-header {
    font-size: 16px;
    font-weight: 300;
    color: #000000;
    margin-bottom: 30px;
    text-align: center;
}
.preview-header {
    font-weight: 900;
    font-size: 44px;
    color: #000000;
    line-height: 44px;
    margin-bottom: 40px;
    text-align: center;
}
.header-arrow {
    width: 210px;
    z-index: 10000000;
    position: relative;
    /* background: black; */
    margin: 0 auto;
    bottom: 61px;
}
.about-icons {
    margin: 50px;
    text-align: center;
    margin: 50px auto;
    display: flex;
    max-width: 1000px;
    justify-content: space-around;
}
.about-icons img {
    width: 100%;
    max-width: 100px;
}
</style>
