<template>
  <div class="v-catalog-item-wrapper">
    <b-container id="b-container">
      <div class="v-catalog-item row">
        <div class="col-5 v-catalog-price-info">
          <div class="v-catalog-item-img">
            <img :src="item.img" alt="" />
          </div>
          <div class="v-catalog-item-price">
            <p>{{ item.monthly }}</p>
            <span
              >Длительность контракта: <b>{{ item.duration }}</b></span
            >
          </div>
        </div>
        <div class="col-6 v-catalog-info">
          <h2 class="v-catalog-item-title">
            <!-- {{info.data[1].title}} -->
            {{ item.title }}
          </h2>
          <div class="v-catalog-item-descr">
            {{ item.full_description }}
          </div>
          <div class="v-catalog-item-buttons">
            <a @click="descPopup = true" class="v-catalog-item-about-link"> Подробнее </a>

            <!-- :id="item.id" -->
            <span v-if="isCart" class="v-catalog-item-about-link">Добавленно</span>
            <button v-else @click="add(item.id)" class="v-catalog-item-add-to-cart">
              Добавить в корзину
            </button>
            <!-- :id="item.id" -->
          </div>
        </div>
        <div v-if="descPopup" id="descPopup + item.id">
          {{ item.full_description }}
          <p
            style="
              border: 1px solid #fb4330;
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              color: #fb4330;
              width: 150px;
              margin: 10px auto;
              cursor: pointer;
              padding: 10px;
            "
            @click="descPopup = false"
          >
            Закрыть
          </p>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from '../plugins/axios';

export default {
  name: 'v-catalog-item',
  data: () => ({
    descPopup: false,
  }),
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['CART']),
    isCart() {
      let isCart = false;
      this.CART.forEach((cartItem) => {
        if (cartItem.tariff.id === this.item.id) {
          isCart = true;
          return;
        }
      });

      return isCart;
    },
  },
  methods: {
    ...mapActions(['CART_API']),

    async add(id) {
      console.log(this.CART);
      if (!localStorage.getItem('token')) {
        alert('Необходимо авторизоваться');
        return;
      } else {
        await axios
          .post(`/tariffs/${id}/add-to-cart`, {
            id: id,
            quantity: 1,
          })
          .then((response) => {
            console.log('add-cart:');
            console.log(id);
            console.log(response);
          });

        this.CART_API().then(() => {});
      }
    },
  },
};
</script>

<style lang="scss">
.v-catalog-item .v-catalog-item-price {
  margin-top: 60px;
}
.v-catalog-item-buttons a {
  color: #fb4030 !important;
}
.v-catalog-item.row:nth-child(2n) {
  flex-direction: row-reverse;
}
@media (max-width: 1200px) {
  .v-catalog-item .v-catalog-item-buttons {
    display: block !important;
  }
}
@media (max-width: 1050px) {
  .v-catalog-item-wrapper .v-catalog-item {
    width: 58vw;
    display: block;
    margin: 0 auto;
  }
  .v-catalog-item .v-catalog-item-title {
    font-size: 40px !important;
  }
  .row {
    display: block !important;
    width: 640px;
    margin: 0 auto !important;
  }
  .v-catalog-item .v-catalog-item-descr {
    width: 63vw;
  }
}
.v-catalog-item .v-catalog-item-img {
  margin: 0 auto;
}
@media (max-width: 900px) {
  .v-catalog-item .v-catalog-price-info {
    padding: 15px !important;
  }
  .v-catalog-item .v-catalog-item-title {
    font-size: 30px !important;
  }
  .v-catalog-item .v-catalog-item-buttons .v-catalog-item-add-to-cart {
    width: 270px !important;
    margin-top: 20px !important;
  }
}
@media (max-width: 1400px) {
  .v-catalog-item {
    gap: 67px !important;
  }
}
.v-catalog-item-wrapper {
  padding: 80px;
  box-sizing: border-box;
  &:nth-child(2n + 1) {
    background: #f5fbfc;
    .v-catalog-item {
      .v-catalog-price-info {
        order: 1;
      }
      .v-catalog-info {
        order: 0;
      }
    }
  }
}
.v-catalog-item {
  margin-bottom: 80px;
  display: flex;
  gap: 102px;
  &:nth-child(2n) {
    .v-catalog-price-info {
      order: 1;
    }
    .v-catalog-info {
      order: 0;
    }
  }
  .v-catalog-item-img {
    background: url('../assets/vip.png') center no-repeat;
    max-width: 338px;
    height: 335px;
    img {
      width: 259.16px;
      height: 388.26px;
      object-fit: contain;
    }
  }
  .v-catalog-price-info {
    padding: 17px 72px 72px 72px;
    box-sizing: border-box;
    background: #ffffff;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 8px 48px rgba(185, 215, 238, 0.4);
    border-radius: 20px;
    max-width: 480px;
    width: 100%;
  }
  .v-catalog-item-price {
    max-width: 338px;
    width: 100%;
    p {
      font-family: 'Gotham Pro', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 35px;
      line-height: 33px;
      color: #fc5944;
    }
    span {
      font-family: 'Gotham Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 182%;
      text-align: center;
      color: #000000;
    }
  }
  .v-catalog-item-title {
    margin-bottom: 53px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 88.4%;
    text-transform: uppercase;
    color: #3d3935;
    text-align: left;
  }
  .v-catalog-item-descr {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
    color: #3d3935;
    text-align: left;
  }
  .v-catalog-item-buttons {
    display: flex;
    gap: 25px;
    margin-top: 48px;
    .v-catalog-item-about-link {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #fb4030;
      padding: 16px 37px;
      box-sizing: border-box;
      border: 1px solid #fb4330;
      border-radius: 4px;
      text-decoration: none;
    }
    .v-catalog-item-add-to-cart {
      outline: none;
      border: none;
      padding: 16px 31px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      box-sizing: border-box;
      background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
      box-shadow: 0px 2px 4px rgba(32, 5, 97, 0.1), 0px 1px 2px rgba(32, 5, 97, 0.08);
      border-radius: 4px;
      position: relative;
      width: 280px;
      text-align: right;
      &:before {
        content: '';
        background: url('../assets/icons/cart_icon_btn.png') center (center/cover)
          no-repeat;
        width: 22px;
        height: 22px;
        position: absolute;
        left: calc(31px);
      }
    }
  }
}
</style>
