<template>
  <section class="main">
    <img
      src="./../assets/tether.png"
      alt="decor"
      class="main__decor-image main__decor-image-top"
    />
    <div class="main__container container">
      <!-- <sidebar /> -->
      <div class="burger-wrapper">
        <div @click="isAppear = true" class="bar-logo">
          <img src="../img/bar.png" alt="" />
        </div>
        <div class="body">
          <div v-bind:class="{ appear: isAppear }" class="burger">
            <p class="sidebar-close-btn" @click="isAppear = false">x</p>
            <ul class="side__navigation">
              <li
                @click="
                  (finActive = true),
                    (detailsActive = false),
                    (hisActive = false),
                    (payActive = false),
                    (passActive = false),
                    (accDetails = false),
                    (fin = true),
                    (inc = false),
                    (his = false),
                    (pay = false),
                    (pass = false)
                "
                style="cursor: pointer"
                :class="
                  finActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link'
                "
              >
                <p class="main__nav-link">
                  <component :is="menuItems[0].svg"></component>
                  <span class="main__nav-text">{{ menuItems[0].title }}</span>
                </p>
              </li>
              <li
                @click="
                  (finActive = false),
                    (detailsActive = true),
                    (hisActive = false),
                    (payActive = false),
                    (passActive = false),
                    (accDetails = true),
                    (inc = false),
                    (fin = false),
                    (his = false),
                    (pay = false),
                    (pass = false)
                "
                style="cursor: pointer"
                :class="
                  detailsActive
                    ? 'main__nav-link main__nav-link_active'
                    : 'main__nav-link'
                "
              >
                <p class="main__nav-link">
                  <component :is="menuItems[1].svg"></component>
                  <span class="main__nav-text">{{ menuItems[1].title }}</span>
                </p>
              </li>
              <li
                @click="
                  (finActive = false),
                    (detailsActive = false),
                    (hisActive = true),
                    (payActive = false),
                    (passActive = false),
                    (his = true),
                    (inc = false),
                    (fin = false),
                    (accDetails = false),
                    (pay = false),
                    (pass = false)
                "
                style="cursor: pointer"
                :class="
                  hisActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link'
                "
              >
                <p :class="'main__nav-link'">
                  <component :is="menuItems[2].svg"></component>
                  <span class="main__nav-text">{{ menuItems[2].title }}</span>
                </p>
              </li>
              <li
                @click="
                  (finActive = false),
                    (detailsActive = false),
                    (hisActive = false),
                    (payActive = false),
                    (passActive = true),
                    (pass = true),
                    (fin = false),
                    (inc = false),
                    (accDetails = false),
                    (his = false),
                    (pay = false)
                "
                style="cursor: pointer"
                :class="
                  passActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link'
                "
              >
                <p :class="'main__nav-link nav-link-pass'">
                  <component :is="menuItems[4].svg"></component>
                  <span class="main__nav-text">{{ menuItems[4].title }}</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <aside class="sidebar">
          <ul class="side__navigation">
            <li
              @click="
                (finActive = true),
                  (detailsActive = false),
                  (hisActive = false),
                  (payActive = false),
                  (passActive = false),
                  (accDetails = false),
                  (fin = true),
                  (inc = false),
                  (his = false),
                  (pay = false),
                  (pass = false)
              "
              style="cursor: pointer"
              :class="
                finActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link'
              "
            >
              <p class="main__nav-link">
                <component :is="menuItems[0].svg"></component>
                <span class="main__nav-text">{{ menuItems[0].title }}</span>
              </p>
            </li>
            <li
              @click="
                (finActive = false),
                  (detailsActive = true),
                  (hisActive = false),
                  (payActive = false),
                  (passActive = false),
                  (accDetails = true),
                  (inc = false),
                  (fin = false),
                  (his = false),
                  (pay = false),
                  (pass = false)
              "
              style="cursor: pointer"
              :class="
                detailsActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link'
              "
            >
              <p class="main__nav-link">
                <component :is="menuItems[1].svg"></component>
                <span class="main__nav-text">{{ menuItems[1].title }}</span>
              </p>
            </li>
            <li
              @click="
                (finActive = false),
                  (detailsActive = false),
                  (hisActive = true),
                  (payActive = false),
                  (passActive = false),
                  (his = true),
                  (inc = false),
                  (fin = false),
                  (accDetails = false),
                  (pay = false),
                  (pass = false)
              "
              style="cursor: pointer"
              :class="
                hisActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link'
              "
            >
              <a :class="'main__nav-link'" href="#">
                <component :is="menuItems[2].svg"></component>
                <span class="main__nav-text">{{ menuItems[2].title }}</span>
              </a>
            </li>
            <li
              @click="
                (finActive = false),
                  (detailsActive = false),
                  (hisActive = false),
                  (payActive = false),
                  (passActive = true),
                  (pass = true),
                  (fin = false),
                  (inc = false),
                  (accDetails = false),
                  (his = false),
                  (pay = false)
              "
              style="cursor: pointer"
              :class="
                passActive ? 'main__nav-link main__nav-link_active' : 'main__nav-link'
              "
            >
              <a :class="'main__nav-link nav-link-pass'" href="#">
                <component :is="menuItems[4].svg"></component>
                <span class="main__nav-text">{{ menuItems[4].title }}</span>
              </a>
            </li>
          </ul>
        </aside>
      </div>
      <div class="main__content">
        <finance v-if="fin" @finance="getfinance" ref="fin"></finance>
        <income v-if="fin"></income>
        <account-details v-if="accDetails" :finance="finance" ref="acc"></account-details>
        <history v-if="his" ref="his"></history>
        <password-change v-if="pass" ref="pass"></password-change>
      </div>
    </div>
    <img
      src="./../assets/avalanche-avax-logo.png"
      alt="decor"
      class="main__decor-image main__decor-image-bottom"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import svgPersonal from './svgIcons/svgPersonal.vue';
import svgFarm from './svgIcons/svgFarm.vue';
import svgHistory from './svgIcons/svgHistory.vue';
// import svgPayment from './svgIcons/svgPayment.vue';
import svgChangePassword from './svgIcons/svgChangePassword.vue';
import AccountDetails from './AccountDetails.vue';
import Finance from './Finance.vue';
import History from './History.vue';
import Income from './Income.vue';
import Sidebar from './Sidebar.vue';
// import Payment from './Payment.vue';
import PasswordChange from './PasswordChange.vue';

export default {
  components: {
    svgPersonal,
    svgFarm,
    svgHistory,
    // svgPayment,
    svgChangePassword,
    Sidebar,
    Finance,
    Income,
    AccountDetails,
    History,
    // Payment,
    PasswordChange,
  },
  data() {
    return {
      isAppear: false,
      finActive: false,
      incActive: false,
      detailsActive: true,
      hisActive: false,
      payActive: false,
      passActive: false,
      fin: false,
      finActive: false,
      inc: false,
      accDetails: true,
      his: false,
      pay: false,
      pass: false,
      activeIndex: 0,
      menuItems: [
        { title: 'Ферма', active: true, svg: 'svgFarm' },
        {
          title: 'Личная информация',
          active: false,
          svg: 'svgPersonal',
        },
        { title: 'История', active: false, svg: 'svgHistory' },
        { title: 'Оплата', active: false, svg: 'svgPayment' },
        {
          title: 'Изменить пароль',
          active: false,
          svg: 'svgChangePassword',
        },
      ],
      offsets: [],
      finance: null,
    };
  },
  mounted() {
    this.PROFILE_API();

    // this.offsets = [
    //   this.$refs["fin"].$el.nextElementSibling.offsetTop,
    //   this.$refs["acc"].$el.offsetTop,
    //   this.$refs["his"].$el.offsetTop,
    //   this.$refs["pay"].$el.offsetTop,
    //   this.$refs["pass"].$el.offsetTop,
    // ];
  },
  methods: {
    ...mapActions(['PROFILE_API']),
    scrollToComp(index) {
      console.log(index);
      // let myIndex = null
      // my
      // window.scrollTo({
      //   top: this.offsets[index],
      //   left: 0,
      //   behavior: 'smooth',
      // });
    },
    getfinance(finance) {
      this.finance = finance;
    },
  },
};
</script>

<style scoped lang="scss">
img.main__decor-image.main__decor-image-bottom {
  bottom: -148px;
}
// ///////////////////BURGER/////// //
.main__container {
  justify-content: space-around;
}
.appear {
  transition: 0.5s !important;
  position: fixed !important;
  left: 0px !important;
}
@media (min-width: 1001px) {
  .bar-logo {
    display: none;
  }
}
@media (max-width: 800px) {
  .burger {
    width: 80vw !important;
  }
}
@media (min-width: 801px) {
  .burger {
    width: 50vw !important;
  }
}
@media (max-width: 1000px) {
  .sidebar {
    display: none;
  }
  .burger {
    display: block !important;
  }
  .container--blocks {
    display: none !important;
  }
}
.bar-logo {
  margin: 51px -167px;
  margin-right: 25px;
  top: -14vh;
  position: relative;
  left: 170px;
}
.sidebar-close-btn {
  top: -2px !important;
  left: 328px !important;
  cursor: pointer;
  position: relative;
  color: black;
  color: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
  border: 1px solid black;
  padding-bottom: -7px;
  width: 40px;
  font-size: 24px;
  border-radius: 8px;
  font-weight: 500;
  font-family: 'Montserrat';
  height: 40px;
}
.burger ul li {
  font-weight: 700;
  padding-top: 35px;
  font-size: 27px;
  text-align: left;
  list-style: none;
  border-bottom: 1px solid black;
  color: #1b2449;
  font-family: sans-serif;
  line-height: 31.8px;
}
.burger {
  overflow: auto;
  display: none;
  border: 5px solid black;
  font-family: sans-serif;
  z-index: 9999;
  text-align: center;
  transition: 0.5s;
  position: fixed;
  left: -810px;
  padding: 20px 20px 20px 20px;
  top: 0px;
  // width: 60vw !important;
  background: white;
  height: 100vh;
}
.body h4 {
  font-family: sans-serif;
  letter-spacing: 12.7px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  margin-top: 50px;
}
.body {
  position: absolute;
}
.bar-logo img {
  padding: 10px;
  border-radius: 10px;
  color: black !important;
  background: black;
  width: 60px;
  // background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
}
// BURGER//////////////////////////

.main {
  overflow: hidden;
}
.nav-link-pass {
  position: relative;
  left: -3px;
}
.main__nav-link {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  padding-left: -13px;
  font-weight: 500;
  font-size: 18px;
  color: #3b3b3b;
  border-bottom: 1px solid #f4f2dd;
}
.side__navigation {
  text-align: left;
}
a.main__nav-link {
  color: black !important;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.side {
  &__sidebar {
    width: 400px;
    padding-left: 6px;
    flex-shrink: 0;
    @media (max-width: 1200px) {
      width: auto;
      max-width: 400px;
    }
    @media (max-width: 1216px) {
      display: none;
    }
  }
  &__navigation {
    position: sticky;
    top: 1px;
  }
  &__nav-item:last-child &__nav-link {
    border-bottom: none;
  }
  &__nav-link {
    display: flex;
    align-items: center;
    padding: 30px 41px;
    font-weight: 500;
    font-size: 18px;
    color: #3b3b3b;
    border-bottom: 1px solid #f4f2dd;
    &_active {
      border: none;
      border-radius: 10px;
      background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
      color: #efefef;
      & .main__nav-icon * {
        fill: #fff;
        fill-opacity: 1;
      }
    }
  }
  &__nav-icon {
    margin-right: 20px;
  }
}
a {
  text-decoration: none !important;
}
.main {
  padding-top: 91px;
  padding-bottom: 97.45px;
  position: relative;
  color: #3d3935;
  &__container {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1200px) {
      padding-right: 0;
    }
    @media (max-width: 1016px) {
      justify-content: center;
      padding-right: 20px;
    }
  }
  &__content {
    width: 700px;
    @media (max-width: 1200px) {
      width: auto;
      max-width: 650px;
    }
    @media (max-width: 700px) {
      width: 100%;
    }
  }
  &__subheading {
    font-size: 24px;
    line-height: 25px;
    margin-bottom: 36px;
  }
  &__input {
    border: none;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 19px;
    padding-top: 19px;
    padding-bottom: 16px;
    color: #3d3935;

    &:focus {
      outline: none;
    }

    border-bottom: 1px solid #99999942;
  }

  &__decor-image {
    @media (max-width: 680px) {
      display: none;
    }
    position: absolute;
    z-index: 999;
    &-top {
      top: -138px;
      left: -47px;
    }
    &-bottom {
      bottom: -43px;
      right: 11px;
    }
  }
}
</style>
