import http from './axios';

const endpoints = {
  cart: 'cart',
  total: 'get-total',
  payment: 'cart/payment',
  profile: 'auth/profile',
  increase: (itemId, quantity) => `tariffs/${itemId}/add-to-cart?quantity=${quantity}`,
  decrease: (itemId, quantity) => `tariffs/${itemId}/reduce-in-cart?quantity=${quantity}`,
  delete: (itemId) => `tariffs/${itemId}/delete-from-cart`,
};

const api = {
  
  async getCartItems() {
    const response = await http.get(endpoints.cart);
    return response.data;
  },
  
  async clearCartItems() {
    const response = await http.delete(endpoints.cart);
    return response.data;
  },
  async getProfile() {
    const response = await http.get(endpoints.profile);
    return response.data;
  },
  async getTotalPrice() {
    const response = await http.get(endpoints.total);
    return response.data;
  },

  async Payment() {
    const response = await http.post(endpoints.payment());
    return response.data;
  },

  async addToCart(itemId, count) {
    const response = await http.post(endpoints.increase(itemId, count));
    return response.data;
  },

  async reduceInCart(itemId, count) {
    const response = await http.post(endpoints.decrease(itemId, count));
    return response.data;
  },

  async deleteFromCart(itemId) {
    const response = await http.delete(endpoints.delete(itemId));
    return response.data;
  },
};

export default api;
