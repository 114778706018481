<template>
    <div class="BreadCrumbs" :style="{ backgroundImage: `url(${info.img})` }">
        <div class="container">
            <p class="BreadCrumbs--title">{{ info.name }}</p>
            <div class="BreadCrumbs--link">
                <p @click="goto()">Главная</p>
                <p><span>/</span>{{ info.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: {},
        },
    },
    methods: {
        goto() {
            this.$router.push("/");
            window.scrollTo(0, 0);
        },
    },
};
</script>

<style lang="scss" scoped>
.BreadCrumbs {
    width: 100%;
    height: 500px;

    background-size: cover;
    overflow: hidden;
    background-position: center;

    padding-top: 168px;
    &--title {
        height: 94px;
        font-weight: 400;
        font-size:60px;
        line-height: 88px;
        color: #ffffff;
        margin-bottom: 28px !important;
    }
    &--link {
        display: flex;
        flex-direction: row;
        align-items: center;
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;

            color: #ffffff;
            cursor: pointer;
            &:first-child {
                letter-spacing: 0.7px;
                text-decoration-line: underline;
                color: #ffd800;
            }
            span {
                margin: 0 10px;
            }
        }
    }
    &--top_link {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
</style>
