<template>
  <svg
    class="main__nav-icon"
    width="23"
    height="18"
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.25 0H2.25C1.00125 0 0.01125 1.00125 0.01125 2.25L0 15.75C0 16.9988 1.00125 18 2.25 18H20.25C21.4988 18 22.5 16.9988 22.5 15.75V2.25C22.5 1.00125 21.4988 0 20.25 0ZM20.25 15.75H2.25V9H20.25V15.75ZM2.25 4.5H20.25V2.25H2.25V4.5Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>