<template>
  <svg
    class="main__nav-icon"
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon/action/https_24px">
      <path
        id="icon/action/https_24px_2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5404 10.2708H21.7487C23.0779 10.2708 24.1654 11.3583 24.1654 12.6875V24.7708C24.1654 26.1 23.0779 27.1875 21.7487 27.1875H7.2487C5.91953 27.1875 4.83203 26.1 4.83203 24.7708V12.6875C4.83203 11.3583 5.91953 10.2708 7.2487 10.2708H8.45703V7.85417C8.45703 4.51917 11.1637 1.8125 14.4987 1.8125C17.8337 1.8125 20.5404 4.51917 20.5404 7.85417V10.2708ZM14.5 4.22949C12.4942 4.22949 10.875 5.84866 10.875 7.85449V10.2712H18.125V7.85449C18.125 5.84866 16.5058 4.22949 14.5 4.22949ZM7.25 24.7708V12.6875H21.75V24.7708H7.25ZM16.9154 18.7292C16.9154 20.0583 15.8279 21.1458 14.4987 21.1458C13.1695 21.1458 12.082 20.0583 12.082 18.7292C12.082 17.4 13.1695 16.3125 14.4987 16.3125C15.8279 16.3125 16.9154 17.4 16.9154 18.7292Z"
        fill="black"
        fill-opacity="0.54"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>