<template>
  <div v-if="step === 1">
    <div v-if="item.quantity >= 1 && deleteItem === true">
      <div class="v-card-item">
        <div class="v-card-item-img">
          <img :src="item.tariff.img" alt="" />
        </div>
        <div class="v-card-item-info">
          <h2>{{ item.tariff.title }}</h2>
          <p>{{ item.tariff.decription }}</p>
        </div>
        <div class="d-flex align-items-center">
          <div class="v-card-item-price">
            <span> {{ item.tariff.monthly }} </span>
            <small> 3000 CHF </small>
          </div>
          <div class="v-cart-item_quantity">
            <span @click="item.quantity--, item.qua--, count(item.tariff.id, item.qua)"
              >-</span
            > 
            <div class="v-card-item-quantity-counter">
              {{ item.quantity }}
            </div>
            <p></p>
            <span @click="item.quantity++, item.qua++, count(item.tariff.id, item.qua)"
              >+</span
            >
            <div class="v-card-item-quantity-price">
              {{ item.tariff.monthly * item.quantity }}
            </div>
          </div>
        </div>
        <button @click="del(item.tariff.id)" class="v-card-item-delete"></button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../plugins/api';
import axios from '../plugins/axios';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'v-card-item',
  props: {
    item: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    deleteItem: true,
    CartItem: '',
    timerId: null,
    timerId2: null,
  }),
  computed: {
    ...mapGetters(['CART']),
  },
  mounted() {
  },
  methods: {
    ...mapActions(['CART_API', 'CART_TOTAL', 'CLEAR_CART_QUANTITY']),
    ...mapMutations(['CLEAR_CART_QUA', 'CLEAR_ITEM_MUT']),

    // зачем нужен асинхронный запрос?
    async del(id) {
      this.deleteItem = false;
      await api
        .deleteFromCart(id, {
          id: id,
        })
        .then(() => {
          this.CART_API().then(() => {});
        });
    },
    count(id, qua) {
      if (this.item.quantity == 0 || this.item.quantity < 1) {
        this.CLEAR_ITEM_MUT(id);
        axios
          .delete(`tariffs/${id}/delete-from-cart`, {
            id: id,
          })
          .then(() => {
            this.CART_API().then(() => {});
          });
      }
      if (qua < 0) {
        clearTimeout(this.timerId);
        this.timerId = window.setTimeout(() => {
          api.reduceInCart(id, Math.abs(qua)).then(() => {
            this.CART_TOTAL().then(() => {});
            this.CART_API().then(() => {});
          });
        }, 1000);
      } else {
        clearTimeout(this.timerId);
        this.timerId = window.setTimeout(() => {
          api.addToCart(id, qua).then(() => {
            this.CART_TOTAL().then(() => {});
            this.CART_API().then(() => {});
          });
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 1200px) {
  .v-card-item .v-card-item-price small {
    text-align: center;
  }
  .v-card-item .v-card-item-delete {
    padding-left: 34px;
    padding-top: 44px;
  }
  .align-items-center {
    float: left;
    margin-left: 27px;
  }
  .v-card-item .v-card-item-info h2 {
    text-align: center !important;
  }
  .v-card-item .v-card-item-info {
    margin: 0 auto;
  }
  .v-card-item {
    display: block !important;
    width: 483px;
    margin: 0 auto;
  }
  .v-card-item .v-card-item-img {
    // text-align: left;
    margin: 0 auto;
    margin-right: 175px !important;
  }
  .v-catalog-item .v-catalog-item-buttons .v-catalog-item-about-link {
    display: block !important;
    padding: 16px 37px;
    width: 270px !important;
    text-align: center;
    min-width: 260px;
    margin: 0 auto;
  }
}
.v-card-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-bottom: 1px solid #999999;
  padding-bottom: 20px;
  margin-bottom: 70px;
  .v-card-item-img {
    background: url('../assets/vip.png') no-repeat;
    width: 147px;
    height: 147px;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 35px;
    img {
      width: 100%;
    }
  }
  .v-card-item-info {
    max-width: 322px;
    width: 100%;
    h2 {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 88.4%;
      text-transform: uppercase;
      color: #3d3935;
      text-align: left;
      margin-bottom: 18px;
    }
    p {
      text-align: left;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #b0b0b0;
    }
  }
  .v-card-item-price {
    display: flex;
    flex-direction: column;
    margin-right: 26px;
    span {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #6d6d6d;
      margin-bottom: 6px;
    }
    small {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      text-decoration-line: line-through;
      color: #8e8e8e;
    }
  }
  .v-cart-item_quantity {
    gap: 4px;
    display: flex;
    .v-card-item-quantity-counter {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      color: #2e2e2e;
      width: 60.77px;
      height: 43.75px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e1e1e1;
    }
    span {
      width: 43px;
      height: 43px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: #efefef;
      cursor: pointer;
    }
    .v-card-item-quantity-price {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #6d6d6d;
      display: flex;
      align-items: center;
      margin-left: 24px;
    }
  }
  .v-card-item-delete {
    width: 13px;
    height: 17px;
    border: none;
    background: none;
    background: url('../assets/icons/delete_24px.png') center no-repeat;
  }
}
</style>
