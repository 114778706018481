<template>
  <div>
    <div id="catalog" class="v-catalog">
      <div class="v-catalog-page-buttons">
        <h1 class="catalog__header">Каталог</h1>
        <router-link class="v-catalog-item-about-link" :to="{ path: '/' }">
          На главную
        </router-link>
        <router-link
          :to="{ name: 'cart', params: { cart_data: CART } }"
          class="v-catalog-item-about-link"
        >
          <div class="catalog__cart-count__cart-count-wrapper">
            <div>Корзина</div>
            <p class="catalog__cart-count">{{ CART.length }}</p>
          </div>
        </router-link>
      </div>
      <v-catalog-item v-for="item in info.data" :key="item.index" :item="item">
      </v-catalog-item>
    </div>
    <mainFooter />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import mainHeader from './mainHeader.vue';
import mainFooter from './mainFooter.vue';
import VCatalogItem from './v-catalog-item.vue';
import axios from '../plugins/axios';

export default {
  name: 'catalogPage',
  components: { VCatalogItem, mainHeader, mainFooter },
  data: () => ({
    info: [],
  }),
  methods: {
    async tarrifs() {
      await axios.get('/tariffs').then((response) => {
        this.info = response.data;
      });
    },
  },
  computed: {
    ...mapGetters(['CART']),
  },
  mounted() {
    this.tarrifs();
  },
};
</script>
<style lang="scss">
.catalog__header {
  font-size: 43px;
  margin-top: -1px;
}
.catalog__cart-count__cart-count-wrapper {
  display: flex;
  justify-content: center;
}
.catalog__cart-count {
  left: 10px;
  margin-left: 5px;
}
@media (max-width: 600px) {
  .v-catalog-page-buttons {
    flex-direction: column;
    width: 175px;
    text-align: center;
    margin-left: 60vw;
    // justify-content: right;
  }
}
.v-catalog-page-buttons {
  display: flex;
  gap: 25px;
  justify-content: right;
  height: 56px;
  margin-top: 48px;
  .v-catalog-item-about-link {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #fb4030;
    padding: 16px 27px;
    margin-right: 10px;
    box-sizing: border-box;
    border: 1px solid #fb4330;
    border-radius: 4px;
    text-decoration: none;
  }
  .v-catalog-item-add-to-cart {
    outline: none;
    border: none;
    padding: 16px 31px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    box-sizing: border-box;
    background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
    box-shadow: 0px 2px 4px rgba(32, 5, 97, 0.1), 0px 1px 2px rgba(32, 5, 97, 0.08);
    border-radius: 4px;
    position: relative;
    width: 280px;
    text-align: right;
    &:before {
      content: '';
      background: url('../assets/icons/cart_icon_btn.png') center (center/cover) no-repeat;
      width: 22px;
      height: 22px;
      position: absolute;
      left: calc(31px);
    }
  }
}
.v-catalog-item-wrapper {
  text-align: center;
}
.row {
  justify-content: center;
}
.v-catalog {
  background: url('../assets/catalog_bg.png') no-repeat;
}
.bg_top {
  position: fixed;
  left: 0;
  width: 292px;
  height: 292px;
  background: url('../assets/bitcoin-bg.png') left no-repeat;
}
.bg_bottom {
  background: url('../assets/bg_bottom.png') right no-repeat;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 292px;
  height: 292px;
}
</style>
