// eslint-disable-next-line
const emailRegExp = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
// eslint-disable-next-line
const passwordRegExp = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

export const emailValidation = (email) => {
    return emailRegExp.test(email)
}

export const passwordValidation = (password) => {
    return passwordRegExp.test(password)
}