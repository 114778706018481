<template>
    <div id="shale" class="shale-container">
        <div class="shale-inner">
            <div v-if="info" class="shale-left">
                <div class="shale-mini-title">{{ info.data.headline }}</div>
                <div class="shale-title">{{ info.data.title }}</div>
                <div class="shale-text">
                    {{ info.data.description }}
                </div>
                <div class="wish-btn">
                    <button>READY TO MINE</button>
                </div>
            </div>
            <div class="shale-right">
                <div class="left-col">
                    <div v-for="item in info" :key="item.id">
                        <div
                            v-for="item2 in item.packages"
                            :key="item2.id"
                            class="shale-box"
                        >
                            <div class="shale-tarif">{{ item2.duration }}</div>
                            <div class="shale-state">
                                {{ item2.is_present }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-col">
                    <div v-for="item in info" :key="item.id">
                        <div
                            v-for="item2 in item.packages"
                            :key="item2.id"
                            class="shale-box"
                        >
                            <div class="shale-tarif">{{ item2.duration }}</div>
                            <div class="shale-state">
                                {{ item2.is_present }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hooper v-for="item in info" :key="item.id" class="shale-hooper">
                <slide v-for="item2 in item.packages" :key="item2.id">
                    <div class="shale-box">
                        <div class="shale-tarif">{{ item2.duration }}</div>
                        <div class="shale-state">
                            {{ item2.is_present }}
                        </div>
                    </div>
                </slide>
            </hooper>
        </div>
    </div>
</template>
<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import axios from "../plugins/axios";

export default {
    name: "shale",
    components: {
        Hooper,
        Slide,
    },
    data() {
        return {
            info: "",
        };
    },
    methods: {
        async Shale() {
            // let config = {
            //     headers: {
            //         Accept: "application/json",
            //         "Accept-Language": "ru"
            //     },
            // };
            await axios.get("/shale").then((response) => {
                this.info = response.data;
            });
            // this.info = info.data;
        },
    },
    mounted() {
        this.Shale();
    },
};
</script>
<style scoped>
@media (max-width: 1000px) {
    .shale-inner {
        margin-top: 250px;
        /* margin: 0 auto; */
    }
    .wish-btn {
        margin: 0 !important;
    }
    .shale-container {
        display: block !important;
    }
    .shale-right {
        display: none;
    }
    .shale-hooper {
        display: block !important;
    }
}
@media (min-width: 1001px) {
    .shale-inner {
        align-items: center;
        display: flex;
    }
}
@media (min-width: 1201px) {
    .shale-right {
        display: flex;
    }
}
.shale-hooper {
    /* justify-content: space-around !important; */
    width: 250px;
    /* height: 250px; */
    display: none;
}
.shale-right {
    height: 90vh;
    overflow-y: scroll;
}
.shale-right::-webkit-scrollbar {
    display: none;
}
.left-col {
    margin-right: 20px;
}
.right-col {
    padding-top: 120px;
}

.shale-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #010f29;
    border-radius: 10px;
    padding: 60px 60px;
    color: #ffffff;
    margin-top: 20px;
}
.shale-tarif {
    white-space: pre;
    font-weight: 700;
    font-size: 25px;
    line-height: 23px;
    margin-bottom: 10px;
}
.shale-state {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    color: rgb(141, 0, 0);
}
.shale-state-if {
    color: #f2b816 !important;
}
/* //////////////////////left///////////////////////////////// */
.shale-left {
    width: 100%;
    max-width: 650px;
    margin-right: 50px;
}
.shale-mini-title {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 46px;
    line-height: 60px;
    color: white;
}
.shale-title {
    font-size: 90px;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 40px;
    color: white;
}
.shale-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 50px;
    color: white;
}
* {
    font-family: sans-serif;
}
.wish-btn {
    box-shadow: 0px 2px 4px rgb(32 5 97 / 10%), 0px 1px 2px rgb(32 5 97 / 8%);
    border-radius: 4px;
    padding: 22px 40px;
    background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    /* margin-left: 100px; */
    position: relative;
    z-index: 1;
    width: 222px;
    margin: 20px 133px;
}
.shale-container {
    background-image: url("../img/bitcoin-bg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    height: 180vh;
    padding: 30px;
    justify-content: center;
    display: flex;
    text-align: left;
    align-items: center;
    overflow: hidden;
}
@media (max-width: 499px) {
    .shale-container {
        height: 121vh !important;
    }
}
</style>
