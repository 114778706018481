import axios from 'axios';

const baseURL = process.env.BASE_API_URL || 'https://symbiote.a-lux.dev/api/';
const StripeURL = 'https://api.stripe.com/v1';

// Aplication
const headers = {
  Accept: 'applicatoin/json',
  'Content-Type': 'applicatoin/json',
  'Accept-Language': localStorage.getItem('lang'),
};
const token = localStorage.getItem('token');
if (token) {
  headers['Authorization'] = `Bearer ${token}`;
}

// Stripe
const StripeHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Authorization: 'Bearer sk_test_4eC39HqLyjWDarjtT1zdp7dc',
};

const instance = axios.create({
  baseURL,
  headers,
});
const paymentInstance = axios.create({
  baseURL: StripeURL,
  headers: StripeHeaders,
});

instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Accept-Language': JSON.parse(localStorage.getItem('lang')),

      // 'Content-Type': 'application/x-www-form-urlencoded'
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export { paymentInstance };
export default instance;
