<template>
  <div class="main__account-info">
    <h2 class="main__subheading">Детали аккаунта</h2>
    <div class="main__account-content account-info">
      <div class="account-info__background-picture">
        <img
          src="./../assets/account-photo.jpg"
          alt="avatar"
          class="account-info__avatar"
        />
      </div>
      <div v-if="profile">
        <div v-if="currentUser" class="account-info__form">
          <div class="account-info__form_input-wrapper">
            <input
              type="text"
              class="account-info__input main__input"
              :value="profile.name"
            />
          </div>
          <div class="account-info__form_input-wrapper">
            <input
              type="text"
              class="account-info__input main__input"
              :value="profile.surname"
            />
          </div>
          <div class="account-info__form_input-wrapper">
            <input
              type="text"
              class="account-info__input main__input"
              :value="profile.birth_date"
            />
          </div>
          <div class="account-info__form_input-wrapper">
            <input
              type="text"
              class="account-info__input main__input"
              :value="profile.email"
            />
          </div>
          <div class="message">{{ message }}</div>
          <div class="account-info__buttons">
            <button
              type="button"
              class="account-info__button button"
              @click="changeProfile()"
            >
              Сохранить
            </button>
            <button
              @click="(editUser = true), (currentUser = false)"
              type="button"
              class="account-info__button button button_no-color"
            >
              Изменить
            </button>
          </div>
        </div>
        <div v-if="editUser" class="account-info__form">
          <input
            type="text"
            class="account-info__input main__input"
            placeholder="Введите имя"
            v-model="editName"
          />
          <input
            type="text"
            class="account-info__input main__input"
            placeholder="Введите фамилию"
            v-model="editSurname"
          />
          <input
            type="text"
            class="account-info__input main__input"
            placeholder="введите день рождения"
            v-model="editBirth"
          />
          <input
            type="email"
            class="account-info__input main__input"
            placeholder="Введите email"
            v-model="editEmail"
          />
          <div class="message">{{ message }}</div>
          <div class="account-info__buttons">
            <button
              class="account-info__button"
              @click="(editUser = false), (currentUser = true)"
            >
              Назад
            </button>
            <button class="account-info__button" @click="Edit()">Сохранить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { emailValidation, passwordValidation } from '../helpers';
// import Finance from "./Finance.vue";
import axios from '../plugins/axios';

export default {
  components: {
    // Finance,
  },
  name: 'AccountDetails',
  // props: {
  //     finance: {
  //         type: Object,
  //         required: true,
  //     },
  // },
  data() {
    return {
      currentUser: true,
      editUser: false,
      editName: '',
      editSurname: '',
      editBirth: '',
      editEmail: '',
      name: '',
      surname: '',
      birth: '',
      email: '',
      // password: "",
      message: '',
    };
  },
  computed: {
    ...mapState(['profile']),
  },
  methods: {
    ...mapActions(['PROFILE_API']),
    async Edit() {
      axios
        .put('/auth/profile', {
          name: this.editName,
          surname: this.editSurname,
          birth_date: this.editBirth,
          email: this.editEmail,
        })
        .then((res) => {
          if (res.status === 200) {
            this.message = 'Успешно обновлено';
            setTimeout(() => {
              location.reload();
            }, 2000);
          }
          console.log(res);
        });
    },
    changeProfile() {
      if (!emailValidation(this.email)) {
        this.message = 'Неверный e-mail';
      } else if (!passwordValidation(this.password)) {
        this.message = 'Проверьте пароль';
      } else {
        this.message = 'Успешно обновлено';
      }
    },
  },
  mounted() {
    // this.PROFILE_API();
  },
};
</script>

<style lang="scss">
.account-info__form_input-wrapper {
  border-bottom: 1px solid #99999942;
}
input {
  border: none;
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 19px;
  padding-top: 19px;
  padding-bottom: 16px;
  color: #3d3935;
}
.account-info__buttons button:last-child {
  background: #ffffff !important;
  border: 1px solid #999999 !important;
  border-radius: 4px;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  padding: 15px 56px;
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #3d3935;
}
.account-info__buttons button:first-child {
  background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */
  color: #ffffff;
  padding: 15px 56px;
}
.account-info {
  &__background-picture {
    padding: 50px;
    height: 230px;
    width: 100%;
    border-radius: 12px;
    background-image: url('../assets/account-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__avatar {
    border-radius: 50%;
  }
  &__form {
    margin-top: 27px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__buttons {
    margin-top: 49px;
  }
  &__button {
    margin-right: 30px;
  }
  margin-bottom: 67px;
}
.message {
  position: absolute;
  bottom: 80px;
}
</style>
